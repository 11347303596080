import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams, Link } from "react-router-dom";
import TrainerCard from '../components/trainer-card';
import { Text } from '../containers/Language';
import { Navigate } from "react-router-dom";
import { cloneDeep } from 'lodash';
import { sort_by_current_grade } from '../logic/sorting';

export const PERSON_DATA = gql`
fragment person on PersonType{
  __typename,
   id,
   firstName,
   lastName,
   avatar{
     id
   },
   grades{
    id,
    description,
    rank,
    discipline{
      id,
      grades{
        id,
      }
    }
  }
}
`;

export const GET_DISCIPLINE_STUDENTS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        dojo{
          id,
          name
        },
        trainers{
          edges{
            node{
              ...person
            }
          }
            
        }
      }
    }
    ${PERSON_DATA}
`;


function get_grade(grades, d_id) {
  for (let element of grades) {
    if (parseInt(element.discipline.id) == d_id) {
      return element
    }
  }
}

function current_grades(trainers, d_id) {
  for (let student of trainers) {
    student.current_grade = get_grade(student.grades, d_id);
  }

}


export default function DisciplineTrainers() {

  const { disciplineId } = useParams();

  const { data, loading, error } = useQuery(
    GET_DISCIPLINE_STUDENTS,
    { variables: { disciplineId } },
  );

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const discipline = data.discipline;

  if (discipline === null) {
    return (<Navigate to={`/landing`} />);
  }

  const trainers = cloneDeep(discipline.trainers.edges.map(e => e.node));

  current_grades(trainers, disciplineId)


  trainers.sort(sort_by_current_grade)

  return (
    <Fragment>
      <div className="dm-container">
        <Link className='back' to={`/discipline/${discipline.id}`} >
          <Text tid="backto" /> {discipline.name}
        </Link>
        <div className='content-block'>
          <h2 className='content-block-header'><Text tid="Trainers" />:</h2>
          <div className="discipline-row">
            {trainers.map(trainer => (
              <TrainerCard key={trainer.id} trainer={trainer} />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );

}