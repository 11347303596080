import { createSlice } from '@reduxjs/toolkit'


export const currentUserSlice = createSlice(
    {
        name: 'currentUser',
        initialState: {
            id: null,
            isTrainer: false,
            isAdmin: false,
            isSuperuser: false,
            dojoId: null,
            dojoName: null,
            teaches: [],
            studies: [],
            teachers: [],
            students: [],
            disciplines:[],
        },
        reducers: {
            setID: (state, action) => {
                state.id = parseInt(action.payload)
            },
            setIsTrainer: (state, action) => {
                state.isTrainer = action.payload
            },
            setIsAdmin: (state, action) => {
                state.isAdmin = action.payload
            },
            setIsSuperuser: (state, action) => {
                state.isSuperuser = action.payload
            },
            setDojoID: (state, action) => {
                state.dojoId =parseInt(action.payload)
            },
            setDojoName: (state, action) => {
                state.dojoName = action.payload
            },
            setTeaches: (state, action) => {
                state.teaches = action.payload
            },
            setStudies: (state, action) => {
                state.studies = action.payload
            },
            setTeachers: (state, action) => {
                state.teachers = action.payload
            },
            setStudents: (state, action) => {
                state.students = action.payload
            },
            setDisciplines:(state,action)=>{
                state.disciplines = action.payload
            },
            clearUser: (state) => {
                state.id = null;
                state.isTrainer = false;
                state.isAdmin = false;
                state.isSuperuser = false;
                state.dojoId = null;
                state.dojoName = null;
            }
        }
    }
)

export const { setID, setIsTrainer, setIsAdmin, setIsSuperuser, setDojoID, setDojoName, clearUser, setTeaches, setStudies, setTeachers, setStudents,setDisciplines } = currentUserSlice.actions
export default currentUserSlice.reducer