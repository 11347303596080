import React from 'react';




export default function Selectable({component, selectionToggleCallback, preSelected, id }) {
    const [isSelected, setSelected] = React.useState(preSelected);

    function toggle(selected) {
        setSelected(selected);
        selectionToggleCallback(id, selected);
    }

    const selectedStyle = isSelected ? "selected" : ""
    return (
        <div className={`selectable_card ${selectedStyle}`} onClick={() => toggle(!isSelected)}>

            {component}

        </div>);

}