import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import DojoCard from '../components/dojo-card';
export const GET_DOJOS = gql`
  query getDojos {
    dojos{
          name,
          id
        }
      }
`;





function Dojos() {
  const { data, loading, error } = useQuery(
    GET_DOJOS,
    { fetchPolicy: "network-only" }
  );
  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  var dojos = data.dojos;
  return (
    <Fragment>
      <div className="dm-container">
        <h1>Dojos</h1>
        <div className="m-2">
          {dojos ? (
            dojos.map(dojo => (
              <DojoCard dojo={dojo} key={dojo.id} />
            ))
          ) : (
              <p>No Dojos</p>
            )}
        </div>
      </div>
    </Fragment>
  );
}

export default Dojos