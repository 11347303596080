
export default class DisciplineOBJ {
  constructor(id, dojo = 0) {
    this.id = parseInt(id);
    this.dojo = parseInt(dojo);
  }
  static get modelName() {
    return 'DisciplineOBJ'
  }

}
