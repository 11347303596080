import React, { Fragment } from 'react';
import TrainerCard from '../components/trainer-card';


export default function StudentList({ students }) {
    
    return (
        <Fragment>
            
            <div className="student-list-row">
                
                {students.map(student => (
                    <TrainerCard key={student.id} trainer={student}/>
                ))}
            </div>
        </Fragment>
    );

}