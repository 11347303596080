import './styles/theme.scss';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import config from 'react-global-configuration';
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "font-awesome/css/font-awesome.css";
import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

import { AbilityContext } from './security/can';
import ability from './security/ability';

import store from './store';
import { Provider } from 'react-redux';

import Pages from './pages';

const LOCAL_CONTAINER = false;
const HETZNER = true;
var httpLink;
// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
const cache = new InMemoryCache();
if (HETZNER) {
  config.set({
    uploadUrl: 'https://api.dojo-trainer.com/upload/',
    mediaUrl: 'https://api.dojo-trainer.com/file/',
    featureUrl: 'https://api.dojo-trainer.com/features/',
    baseurl: 'https://api.dojo-trainer.com/',
  })
  httpLink = new HttpLink({
    uri: 'https://api.dojo-trainer.com/graphql/',
  });
}
else if (LOCAL_CONTAINER) {
  config.set({
    uploadUrl: 'http://localhost:1221/upload/',
    mediaUrl: 'http://localhost:1221/file/',
    featureUrl: 'http://localhost:1221/features/',
    baseurl: 'http://localhost:1221/',
  })
  httpLink = new HttpLink({
    uri: 'http://localhost:1221/graphql/',
  });
}
else {
  config.set({
    uploadUrl: 'http://localhost:8000/upload/',
    mediaUrl: 'http://localhost:8000/file/',
    featureUrl: 'http://localhost:8000/features/',
    baseurl: 'http://localhost:8000/',
  })
  httpLink = new HttpLink({
    uri: 'http://localhost:8000/graphql/',
  });
}







const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  var authToken = null;
  if (localStorage.getItem('token')) {
    authToken = `JWT ${localStorage.getItem('token')}`
  }

  operation.setContext({
    headers: {
      authorization: authToken,
      'X-CSRF-Token': Cookies.get('csrftoken')
    },
  });
  return forward(operation);
});
const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink),
});


ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <AbilityContext.Provider value={ability}>
        <Pages />
      </AbilityContext.Provider>
    </ApolloProvider>
  </Provider>
  ,
  document.getElementById('root'),
);
