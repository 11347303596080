import React, { Fragment, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams, Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import SaveIcon from '../assets/icons/save.svg';
import SortIcon from '../assets/icons/Sort_both.svg';
import { Text } from '../containers/Language';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import RemoveImage from '../assets/icons/remove.svg';
import { Navigate } from "react-router-dom";
import Img from 'react-image'
import { Alert } from 'reactstrap'
import EditableLabel from '../components/editable-label';
import DisciplineOBJ from '../DataClasses/Discipline';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../security/can';
import { Can } from '../security/can';
import { cloneDeep } from 'lodash';

export const GRADE_DATA = gql`
fragment grade on GradeType{
  __typename,
   id,
   description,
   rank,
}
`;


export const GET_DISCIPLINE_GRADES = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        dojo{
          id,
          name
        },
        grades{
          ...grade
        }
      }
    }
    ${GRADE_DATA}
`;


const CREATE_GRADE = gql`
mutation CreateGrade($input:GradeInput!){
  createGrade(input:$input){
   ok,
   grade{
     id,
     description,
     rank,
   }
  }
}`;

const UPDATE_GRADE = gql`
mutation UpdateGrade($input:GradeInput!){
  updateGrade(input:$input){
   ok,
   grade{
    id,
    description,
    rank,
  }
  }
}`;

const DELETE_GRADE = gql`
mutation DeleteGrade($id:ID!){
  deleteGrade(id:$id){
   ok,
  }
}`;


export default function DisciplineGrades() {

    const languageContext = useContext(LanguageContext);


    const { disciplineId } = useParams();
    const [grades, setGrades] = React.useState([]);
    const [description, setDescription] = React.useState("");
    const [show, setShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [deleted, setDeleted] = React.useState([]);

    const onChangeLabel = event => {
        const value = event.target.value;
        setDescription(value);
    };


    const [updateMutation] = useMutation(
        UPDATE_GRADE
    );



    function updateGrades(list) {
        var rank = 0;
        list.forEach(element => {
            element.rank = rank;
            rank++;
        });
        setGrades(list);
    }

    function handle_undeletable(grade) {

        var e = errors;
        var error_msg = languageContext.dictionary["couldnotdelete"] || "couldnotdelete";
        e.push(error_msg + grade.description + "(" + grade.rank + ")");
        setErrors(e);
        var tmp = grades;
        tmp.push(grade);
        setGrades(tmp);
        setShow(true)
    }

    function saveGrades() {
        setShow(false)
        deleted.forEach(element => {
            deleteMutation({ variables: { id: element.id } }).catch(e => {
                if (e.message.includes("referenced through a protected foreign key")) {
                    handle_undeletable(element);
                }

            });
        });

        grades.forEach(element => {
            var input = {
                description: element.description,
                id: element.id,
                rank: element.rank,
                disciplineId: disciplineId
            }
            updateMutation({ variables: { input: input } });
        });

    }

    const { data, loading, error, refetch } = useQuery(
        GET_DISCIPLINE_GRADES,
        { variables: { disciplineId } },
    );

    const [createMutation] = useMutation(
        CREATE_GRADE
    );

    const [deleteMutation] = useMutation(DELETE_GRADE);

    function createGrade() {
        var input = {
            description: description,
            rank: grades.length,
            disciplineId: disciplineId
        }
        createMutation({ variables: { input: input } }).then(res => {
            // var tmp = grades;
            // tmp.push(res.data.createGrade.grade);
            // setDescription("");
            // document.getElementById('desc').value = '';
            //setGrades(tmp);
            refetch()

        }, err => console.log(err));
    }

    function handleEnter(e) {
        if (e.key === 'Enter') {
            createGrade();
        }
    }

    function remove(id) {
        var tmp = deleted;
        tmp.push(grades.filter(function (value, index, ar) {
            return value.id === id;
        })[0]);

        setDeleted(tmp);
        var g = grades.filter(function (value, index, ar) {
            return value.id !== id;
        });
        setGrades(g);
    }

    function edit(value, props) {
        var list = grades;
        list.forEach(element => {
            if (element.id === props)
                element.description = value;
        });
        setGrades(list);
        saveGrades();
    }

    function renderErrors() {
        if (show) {
            return (
                <Alert color="danger">
                    {errors.map(error => (
                        <span>{error}</span>
                    ))}
                </Alert>
            )
        }

    }


    const ability = useAbility(AbilityContext);



    useEffect(() => {
        if (data) {
            let discipline = data.discipline;
            let g = cloneDeep(discipline.grades)
            g.sort((a, b) => (a.rank > b.rank ? 1 : -1));
            setGrades(g);
        }

    }, [data])

    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;

    const discipline = data.discipline;

    if (discipline === null) {
        return (<Navigate to={`/landing`} />);
    }

    function renderGrade(grade) {
        return (
            <div key={"grade_" + grade.id} className="grade-row">

                <div>
                    <img src={[SortIcon]} style={{ height: '1em' }} alt="reorder" />
                </div>
                <EditableLabel canEdit={ability.can('editgrades', new DisciplineOBJ(discipline.id))} callback={edit} label={grade.description} props={grade.id} />


                <span>{grade.rank}</span>


                <Can I="editgrades" on={new DisciplineOBJ(discipline.id)} >
                    <button type="button" onClick={() => remove(grade.id)} className="btn btn-nopadding">
                        <Img className="btnIcon-small remove-icn" src={[RemoveImage]} />
                    </button>
                </Can>

            </div>
        );
    }
    return (
        <Fragment>
            <div className="dm-container">
                <Link className='back' to={`/discipline/${discipline.id}`} >
                    <Text tid="backto" /> {discipline.name}
                </Link>
                <div className='content-block'>
                    <h2 className='content-block-header'>{discipline.name} <Text tid="GradeSystem" /></h2>
                    {renderErrors()}
                    <div className="grade-header-row gradesListHeader">
                        <div></div>
                        <div ><Text tid="Description" /></div>
                        <div ><Text tid="Rank" /></div>
                        <div ></div>
                    </div>
                    <ReactSortable list={grades} setList={updateGrades}>

                        {grades.map(grade => renderGrade(grade))}
                    </ReactSortable>
                    <Can I="editgrades" on={new DisciplineOBJ(discipline.id)} >
                        <div className="row">
                            <button className="col-2 btn btn-primary" onClick={saveGrades}><Text tid="Save" /></button>
                        </div>
                    </Can>
                </div>
                <Can I="editgrades" on={new DisciplineOBJ(discipline.id)} >
                    <div className='content-block'>
                        <h2 className='content-block-header'><Text tid="AddNewGrade" /></h2>
                        <div className="input-group">
                            <input type="text" id="desc" className="form-control" onKeyDown={handleEnter} placeholder={languageContext.dictionary["Description"] || "Description"} onChange={onChangeLabel} />
                            <input type="submit" hidden />
                            <button onClick={createGrade} type="submit" className="btn btn-primary"><img src={[SaveIcon]} style={{ height: '1.5em' }} alt="Edit" /></button>
                        </div>
                    </div>

                </Can>
            </div>

        </Fragment>
    );

}