import React, { useState, useContext } from 'react';
import { Text } from '../../containers/Language';
import gql from 'graphql-tag';
import { Loading } from '../../components';
import { useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faSave
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";



function MemberPayment({ member }) {


    function get_last_payment(payments) {
        if (payments.length > 0) {
            payments.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.date) < new Date(a.date);
            });

            return payments[0].payed ? payments[0] : null
        }
        return null
    }

    function get_next_payment(membership, payments) {
        if (!membership) {
            return []
        }

        var all_dates = []

        payments.map(payment => {
            if (!payment.payed) {
                all_dates.push(new Date(payment.date))
            }
        })

        return all_dates
    }



    var _payments = member.membership ? member.membership.payments.slice() : []
    var _last_payment = get_last_payment(_payments)
    var _all_missing_dates = get_next_payment(member.membership, _payments)
    var _next_due_payment = _all_missing_dates.length > 0 ? _all_missing_dates[_all_missing_dates.length - 1].toLocaleDateString() : "";
    var _missing_date = _all_missing_dates.length > 1 ? _all_missing_dates.slice(0, _all_missing_dates.length - 1) : []


    const [last_payment, setLastPayment] = useState(_last_payment)
    const [all_missing_dates, SetAllMissingDates] = useState(_all_missing_dates)
    const [next_due_payment, setNextDuePayment] = useState(_next_due_payment)
    const [missing_date, setMissingDate] = useState(_missing_date)

    function update_display(payments) {
        var _last_payment = get_last_payment(payments)
        var _all_missing_dates = get_next_payment(member.membership, payments)

        var _next_due_payment = _all_missing_dates.length > 0 ? _all_missing_dates[_all_missing_dates.length - 1].toLocaleDateString() : "";
        var _missing_date = _all_missing_dates.length > 1 ? _all_missing_dates.slice(0, _all_missing_dates.length - 1) : []

        setLastPayment(_last_payment)
        SetAllMissingDates(_all_missing_dates)
        setNextDuePayment(_next_due_payment)
        setMissingDate(_missing_date)
    }


    function EditPayments({ member, open_payments }) {
        const [edit, setEdit] = useState(false);
        const [markedForPayed, setMarkedforPayment] = useState([])



        const MARK_AS_PAYED = gql`
        mutation markPaymentsAsPayed($input:PaymentInput!){
            markPaymentsAsPayed(input:$input){
                ok,
                payments{
                    id,
                    payed,
                    date
                }
            }
        }`;


        const [create, { loading, error }] = useMutation(
            MARK_AS_PAYED, {
            onCompleted(data) {
                update_display(data.markPaymentsAsPayed.payments);
            }
        }
        );


        function mark(value, event) {
            if (event.target.checked) {
                let new_array = markedForPayed
                new_array.push(new Date(value).toISOString().split("T")[0])
                setMarkedforPayment(new_array)
            } else {
                var filtered = markedForPayed.filter(function (inner_value, index, arr) {
                    return inner_value != new Date(value).toISOString().split("T")[0];
                });
                setMarkedforPayment(filtered)
            }
        }

        function save() {
            let payment_input = {
                "membershipId": member.membership.id,
                "dates": markedForPayed
            }
            create({ variables: { input: payment_input } });
            setEdit(false)
        }

        if (edit) {
            return (
                <div className='dialog_card'>
                    <div className='dialog_card_inner'>
                        <h2>{member.firstName}&nbsp;{member.lastName}: <Text tid="markPayed" /></h2>
                        <div className='open_payments'>
                            {open_payments.map(p => {
                                return (
                                    <div key={`paymend_${p.toLocaleDateString()}`} className='open_payment'>
                                        <label htmlFor={`paymend_${p.toLocaleDateString()}`}>{p.toLocaleDateString()}</label>
                                        <input id={`paymend_${p.toLocaleDateString()}`} type="checkbox" onChange={e => mark(p, e)} />
                                    </div>
                                )
                            })}
                        </div>
                        <button className="btn btn-primary m-2" onClick={e => save()}><Text tid="Save" /></button>

                        <button className="btn btn-primary m-2" onClick={() => setEdit(false)}><Text tid="Cancel" /></button>

                    </div>
                </div>
            )
        } else {
            return (<button className='table_button' onClick={() => { setEdit(true) }}><FontAwesomeIcon icon={faEdit} className="mr-2 table_edit" /></button>)
        }

    }


    return (
        <div className='payments_table_row'>

            <div>
                {member.firstName}&nbsp;{member.lastName}
            </div>
            <div>{last_payment ? new Date(last_payment.date).toLocaleDateString() : <Text tid="noPayment" />}</div>
            <div>{next_due_payment}</div>
            <div className='missing_dates'>
                {
                    missing_date.map(d => {
                        return <span key={`payment_key${member.id}_${d}`}>{new Date(d).toLocaleDateString()}</span>
                    })
                }
            </div>
            <div><EditPayments open_payments={all_missing_dates} member={member} /> </div>
        </div>
    )
}

export default function Payments() {

    const dojo_id = useSelector((state) => state.currentUser.dojoId)
    
    const GET_PAYMENTS = gql`
    query PersonPayments{
        persons{
            edges{
                node{
                        id,
                        firstName,
                        lastName,
                        membership{
                            startDate,
                            id,
                            payments{
                                id,
                                date,
                                payed
                            },
                            membershipType{
                                price{
                                    asString
                                },
                                interval
                            }
                        }
                    }
            }
        }
    }
    `;

    const { data, loading, error, refetch } = useQuery(
        GET_PAYMENTS,
    );

    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;


    var members = data.persons.edges.map(edge => edge.node)

    return (
        <>
            <div className="dm-container">
            <Link className='back' to={`/dojo/${dojo_id}`} >
                <Text tid="backto" /> <Text tid="ManageDojo" />
            </Link>
                <div className='content-block'>
                    <h2 className='content-block-header'>  <Text tid="memberPayments" /></h2>
                    <div className='payments_table'>
                        <div className='membership_types_header'><Text tid="member" /></div>
                        <div className='membership_types_header'><Text tid="lastPayment" /></div>
                        <div className='membership_types_header'><Text tid="nextPayment" /></div>
                        <div className='membership_types_header'><Text tid="missingPayments" /></div>
                        <div></div>

                        {members.map(member => <MemberPayment key={`member_${member.id}`} member={member} />)}
                    </div>
                </div>
            </div>
        </>
    );
}