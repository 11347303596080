import React, { Fragment } from 'react';
import Header from '../components/header';
import { useState } from 'react';
import { Text } from '../containers/Language';
import config from 'react-global-configuration';
import Cookies from 'js-cookie'

export default function ContactPrivate() {

    const [msg, set_msg] = useState("")
    const [posted, set_posted] = useState("")
    function submit() {


        fetch(config.get('baseurl') + "contact/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                authorization: "JWT " + localStorage.getItem("token"),
                "X-CSRF-Token": Cookies.get("csrftoken"),
            },
            body: JSON.stringify({
                msg: msg,
                email: "",
                captcha: true,
            })
        }).then(function (response) {
            set_msg("");
            set_posted("supportRequestSent")
        })



    }

    return <Fragment>
        <div className='splash-container'>
            <div className="dm-container">
                <div className="dm-container">
                    <div className='content-block'>
                        <h1><Text tid="Support" /></h1>
                        <div className="request_success" ><Text tid={posted} /></div>
                        <div className="row mb-3">
                            <label htmlFor="msg"><Text tid="support_message" /></label>
                            <textarea id="msg" className="form-control mb-2" onChange={e => set_msg(e.target.value)} rows="20" value={msg}/>
                        </div>
                        <div className='contact_form'>
                            <div className="row mb-3">
                                <button className="btn btn-primary mb-2" onClick={e => submit()}><Text tid="send" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}

