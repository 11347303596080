import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading, Participant } from '../components';
import { useParams } from "react-router-dom";
import AddImage from '../assets/icons/add.svg';
import Img from 'react-image'
import Select from 'react-select';
import { Text } from '../containers/Language';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import { Can } from '../security/can';
import DisciplineOBJ from '../DataClasses/Discipline';
import { Navigate, Link } from "react-router-dom";
import { DateTimePicker } from 'react-tempusdominus-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarXmark,
    faPenToSquare,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { cloneDeep } from 'lodash';

const ADD_PARTICIPANT = gql`mutation ToggleExamParticipation($examId: Int!,$studentId:Int!,$gradeId:Int!,$targetGradeId:Int!,$delete:Boolean!,$certificate:Boolean!,$payed:Boolean!){
    toggleExamParticipation(examId:$examId, studentId:$studentId,gradeId:$gradeId,targetGradeId:$targetGradeId,delete:$delete,certificate:$certificate,payed:$payed){
      ok,
    }
  }
`;


const UPDATE_EXAM = gql`
mutation UpdateExam($id: ID!, $dateTime:DateTime!,$delete:Boolean!) {
    updateExam(id:$id,dateTime:$dateTime,delete:$delete){
        ok
    }
}
`;


export const GET_EXAM = gql`
query Exam($examId: ID!){
  exam(id: $examId){
        dateTime,
        id,
        discipline{
            id,
            name,
            dojo{
                id,
                name,
            },
            grades{
                id,
                description,
                rank
            }
            students{
                edges{
                    node{
                        id,
                        firstName,
                        lastName,
                        grades{
                            id,
                            discipline{
                              id,
                              grades{
                                id,
                                description,
                                rank
                              }
                            },
                            rank
                        }
                    }
                }
 
              }
        },
        participants{
                    person{
                        id,
                        firstName,
                        lastName,
                        grades{
                            id,
                            discipline{
                              id,
                              grades{
                                id,
                                description,
                                rank
                              }
                            },
                            rank
                        }
                    },
                    result{
                        id,
                        rank,
                        description
                    },
                    target{
                        id,
                        rank,
                        description
                    },
                    certificate,
                    payed
            
        }
      }
    }
`;




function DateTimeLabel({ date, examId, edit }) {

    const [dateTime, setDateTime] = useState(date)
    const firstRender = useRef(true)

    const [updateExam] = useMutation(UPDATE_EXAM);

    useEffect(() => {
        if (firstRender.current === false) {
            updateExam({
                variables: {
                    id: parseInt(examId),
                    dateTime: dateTime.toISOString(),
                    delete: false
                }
            })
        } else {
            firstRender.current = false
        }

        return;

    }, [dateTime, examId, updateExam])

    function onChangeStartDatetime(event) {
        setDateTime(new Date(event.date))
    }

    const formatedStartDate = dateTime.toLocaleDateString();
    const formatedStartTime = dateTime.getHours() + ":" + dateTime.getMinutes();

    if (edit === "edit") {
        return (
            <>
                <DateTimePicker
                    date={dateTime}
                    readOnly={false}
                    onChange={onChangeStartDatetime}
                    format="DD/MM/YYYY HH:mm"
                />
            </>
        )
    } else {
        return <span>{formatedStartDate}: {formatedStartTime}</span>;
    }
}


function DeleteDialog({ exam, discipline }) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const [updateExam] = useMutation(UPDATE_EXAM);


    function delete_exam() {
        updateExam({
            variables: {
                id: parseInt(exam.id),
                dateTime: exam.dateTime,
                delete: true
            },
            onCompleted: () => { navigate(`/discipline/exams/${discipline.id}/refresh`); }
        })

    }

    if (show) {
        return (

            <div className='delete_user_dialog'>
                <div className='delete_user_dialog_inner'>
                    <div>{<Text tid="delete_exam" />} ?</div>
                    <div className='delete_user_dialog_inner_warning'><Text tid="delete_exam_text" /></div>
                    <div className='delete_user_dialog_inner_action_bar'>
                        <button className="btn btn-primary" onClick={e => setShow(false)}>
                            <Text tid="cancel" /> <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <button className="btn btn-warning" onClick={() => delete_exam()}>YES</button>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <button className="btn btn-warning" onClick={e => setShow(true)}>
                <Text tid="delete" /> <FontAwesomeIcon icon={faCalendarXmark} />
            </button>
        );
    }
}

export default function Exam() {



    const { examId, edit } = useParams();


    const [addStudent, setStudent] = React.useState("");
    const [result, setResult] = React.useState("");
    const [targetGrade, setTargetGrade] = useState("")

    const { data, loading, error } = useQuery(
        GET_EXAM,
        { variables: { examId } },

    );


    const [toggleParticipant] = useMutation(ADD_PARTICIPANT);


    function update_participant(student, grade, target_grade, certificate, payed) {


        const gid = grade ? parseInt(grade.id) : -1
        const tgid = target_grade ? parseInt(target_grade.id) : -1
        toggleParticipant({
            variables: {
                examId: parseInt(examId),
                studentId: parseInt(student.id),
                gradeId: gid,
                targetGradeId: tgid,
                delete: false,
                certificate: certificate,
                payed: payed
            },
            refetchQueries: [
                {
                    query: GET_EXAM,
                    variables: { examId },
                },
            ]
        })
    }

    function remove_participant(student) {
        toggleParticipant({
            variables: { examId: examId, studentId: student.id, gradeId: -1, targetGradeId: -1, delete: true },
            refetchQueries: [
                {
                    query: GET_EXAM,
                    variables: { examId },
                },
            ]
        })
    }

    function add_event() {
        if (addStudent !== "") {
            toggleParticipant({
                variables: {
                    examId: parseInt(examId),
                    studentId: parseInt(addStudent),
                    gradeId: result !== "" ? parseInt(result) : -1,
                    targetGradeId: targetGrade !== "" ? parseInt(targetGrade) : -1,
                    delete: false,
                    certificate: false,
                    payed: false
                },
                refetchQueries: [
                    {
                        query: GET_EXAM,
                        variables: { examId },
                    },
                ]
            })
            setStudent("");
            setResult("");
            setTargetGrade("");
        }
    }


    function isSelectable(student, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (student.id === list[i].person.id) {
                return false;
            }
        }

        return true;
    }

    function handleStudentSelect(student) {
        setStudent(student.id);
    }


    function handleTargetGradeSelect(grade) {
        setTargetGrade(grade.id)
    }

    const languageContext = useContext(LanguageContext);

    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;

    const exam = data.exam;

    if (exam === null) {
        return (<Navigate to={`/landing`} />);
    }

    const discipline = exam.discipline;
    const grades = discipline.grades;
    const total_students = cloneDeep(discipline.students.edges.map(e => e.node));

    const participants = cloneDeep(exam.participants);

    let selectable_Students = total_students.filter(student => isSelectable(student, participants));
    if (edit === "edit") {
        return (
            <Fragment>
                <div className="dm-container">
                    <Link className='back' to={`/discipline/${discipline.id}`} >
                        <Text tid="backto" /> {discipline.name}
                    </Link>

                    <div className='content-block'>
                        <h2 className='content-block-header'>
                            {discipline.name} <Text tid="Exam" />
                            <DateTimeLabel date={(new Date(exam.dateTime))} edit={edit} examId={examId} />
                        </h2>
                        <div className="participant_row">

                            <div><Text tid="Student" /></div>
                            <div><Text tid="targetGrade" /></div>
                            <div><Text tid="resultGrade" /></div>
                            <div><Text tid="certificate" /></div>
                            <div><Text tid="feepayed" /></div>
                            <div></div>
                            {participants.map(o => (
                                <Participant editable={true} key={o.person.id} student={o.person} target_grade={o.target} grade={o.result} grades={grades} update_callback={update_participant} delete_callback={remove_participant} payed={o.payed} certificate={o.certificate} />
                            ))}
                        </div>
                        <h2 className='exam-addstudent'><Text tid="AddStudentToExam" /></h2>
                        <div className="add_participant_row">

                            <div><Text tid="Student" /></div>
                            <div><Text tid="targetGrade" /></div>
                            <div></div>

                            <div className="">
                                <Select
                                    options={selectable_Students}
                                    placeholder={languageContext.dictionary["selectStudent"] || "selectStudent"}
                                    getOptionLabel={student =>
                                        `${student.firstName} ${student.lastName}`
                                    }
                                    defaultValue={null}
                                    className="react-selectcomponent"
                                    onChange={handleStudentSelect}
                                />
                            </div>

                            <div className="">
                                <Select
                                    options={grades}
                                    placeholder={languageContext.dictionary["selectTargetGrade"] || "selectTargetGrade"}
                                    getOptionLabel={option =>
                                        `${option.description}`
                                    }
                                    defaultValue={null}
                                    className="react-selectcomponent"
                                    onChange={handleTargetGradeSelect}
                                />
                            </div>

                            <div className="">
                                <button className="btn btn-primary" onClick={add_event}>
                                    <Img src={[AddImage]} className="btnIcon-small" />
                                </button>
                            </div>
                        </div>
                        <div className='exam-action-bar'>
                            <Link className='back' to={`/exam/${examId}`} >
                                <Text tid="finished" />
                            </Link>
                            <div></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className="dm-container">
                    <Link className='back' to={`/discipline/exams/${discipline.id}`} >
                        <Text tid="backto" /> {discipline.name} <Text tid="exams" />
                    </Link>
                    <div className='content-block'>
                        <h2 className='content-block-header'>{discipline.name} <Text tid="Exam" /> {new Date(exam.dateTime).toLocaleString()} </h2>
                        <div className="participant_row">

                            <div><Text tid="Student" /></div>
                            <div><Text tid="targetGrade" /></div>
                            <div><Text tid="resultGrade" /></div>
                            <div><Text tid="certificate" /></div>
                            <div><Text tid="feepayed" /></div>
                            <div></div>
                            {participants.map(o => (
                                <Participant editable={false} key={o.person.id} student={o.person} target_grade={o.target} grade={o.result} grades={grades} update_callback={update_participant} delete_callback={remove_participant} payed={o.payed} certificate={o.certificate} />
                            ))}

                        </div>
                        <Can I="editexam" on={new DisciplineOBJ(discipline.id)}>
                            <div className='exam-action-bar'>
                                <Link className="btn btn-primary" to={`/exam/${exam.id}/edit`}>
                                    <Text tid="edit" /> <FontAwesomeIcon icon={faPenToSquare} />
                                </Link>
                                <DeleteDialog exam={exam} discipline={discipline} />
                            </div>
                        </Can>
                    </div>
                </div>
            </Fragment>
        );
    }
}
