import { createSlice } from '@reduxjs/toolkit'

export const sessionsSlice = createSlice({
    name:'sessions',
    initialState:{
        dirty:0,
    },
    reducers:{
        toggleDirty:(state,action)=>{
            state.dirty = state.dirty+1
        },
    
    }
})


export const {toggleDirty}= sessionsSlice.actions
export default sessionsSlice.reducer