import { createSlice } from '@reduxjs/toolkit'

export const secureFileSlice = createSlice({
    name:'files',
    initialState:{
        files:{},
    },
    reducers:{
        addFile:(state,action)=>{
            if(!(action.payload in state.files)){
                state.files[action.payload.id] = action.payload.file
            }
        },
    
    }
})


export const {addFile}= secureFileSlice.actions
export default secureFileSlice.reducer