import React, { useState } from 'react';
import EditIcon from '../assets/icons/edit_pen.svg';
import SaveIcon from '../assets/icons/save.svg';
import Img from 'react-image'


export default function EditableLabel({ label, canEdit, callback, select_values = null, props = null }) {
    const [readMode, setMode] = useState(true);
    const [value, setValue] = useState(label);
    let multiple = Array.isArray(label)

    const onChangeLabel = event => {
        const value = event.target.value;
        setValue(value);
    };

    function onChangeLabels(event, index) {
        const newValue = event.target.value;
        let v = value;
        v[index] = newValue;
        setValue(v)
    }

    function edit() {
        setMode(false);
    }

    function save() {
        setMode(true);
        callback(value, props);
    }

    function createEditIcon() {
        if (canEdit && readMode) {
            return (
                <button type="button" onClick={edit} className="btn">
                    <Img className="btnIcon-small" src={[EditIcon]} />
                </button>
            );

        } else if (canEdit && !readMode) {
            return (
                <button type="button" onClick={save} className="btn btn-primary">
                    <Img className="btnIcon-small" src={[SaveIcon]} />
                </button>
            );
        } else {
            return ("");
        }
    }


    function createLabel() {
        if (readMode) {
            if (multiple) {
                return (
                    value.map((l, i) => {
                        return (
                            <span className='editable-label-multi-label'>
                                {l}
                            </span>
                        )
                    })
                )
            } else {
                return value;
            }
        } else {

            if (select_values == null) {
                if (multiple) {
                    return (
                        value.map((l, i) => {
                            return (
                                <span className='editable-label-multi-label'>
                                    <input className="form-control" type="text" defaultValue={l} onChange={e => onChangeLabels(e, i)} />
                                </span>
                            )
                        })
                    )
                } else {
                    return <input className="form-control" type="text" defaultValue={value} onChange={onChangeLabel} />;
                }

            } else {
                const items = select_values.map((val) =>
                    <option key={val[0]} value={val[1]}>{val[1]}</option>
                );
                return (
                    <select className='form-select' onChange={onChangeLabel} defaultValue={value}>
                        <option key="" value="-1"></option>
                        {items}
                    </select>
                );
            }

        }
    }


    return (
        <div className="input-group editable-lable-group">{createLabel()} {createEditIcon()}</div>
    );
}
