import React, { Fragment } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from "react-router-dom";
import { clearUser } from '../storeSlices/currentUserSlice';
import { useSelector, useDispatch } from 'react-redux';
import LocalStorageProvider from './LocalStorageProvider';
import { useApolloClient } from '@apollo/client'
import { enabled } from '../components/features';
import { Link } from "react-router-dom";
import { Text } from '../containers/Language';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar

} from "@fortawesome/free-solid-svg-icons";



export default function PrivateLayout(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useApolloClient();


    const dojo_name = useSelector((state) => state.currentUser.dojoName)
    const dojo_id = useSelector((state) => state.currentUser.dojoId)

    function logout() {
        window.localStorage.clear();
        sessionStorage.clear();
        dispatch(clearUser())
        navigate("/");
        client.clearStore()
    }


    function render_pro_button() {
        if (enabled("session_topics")) {
            return (<></>)
        } else {
            return (
                <Link className="btn btn-primary feature_pro_button" to={`/subscriptions/${dojo_id}`}>
                        <FontAwesomeIcon icon={faStar} />
                    <Text tid="get_pro" />
                </Link>
            )
        }
    }

    return (
        <Fragment>
            <LocalStorageProvider>
                <div className='private_container'>
                    <div className='private_sidebar'>
                        <Sidebar />
                    </div>
                    <div className='private_main'>
                        <div className='private_main_header'>
                            <div className='private_main_header_left'>
                                {dojo_name}
                            </div>
                            <div className='private_main_header_right'>
                                {render_pro_button()}
                                <a href="#" className="logout_btn" onClick={() => logout()}></a>
                            </div>
                        </div>
                        <div className='private_main_content'>
                            {props.children}
                        </div>
                    </div>

                </div>
            </LocalStorageProvider>
        </Fragment>
    );
}

