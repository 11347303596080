import React, { Fragment, useState } from 'react';
import Selectable from '../components/selectable';
import { SecureImage } from "../components/SecureImage";
import Profile from '../assets/icons/profile.svg';
import {sort_by_current_grade} from '../logic/sorting';

function getGrade(student) {
    let grade = ""
    if (student.current_grade){
        grade =student.current_grade.description;
        if(grade.length>10){
            grade = `${student.current_grade.description.substring(0, 10)} ...`
        }
    }

    if(grade!==""){
        return "(" + grade + ")";
    }else{
        return""
    }
    
}


function Student({ student }) {
    return (
        <>
            <SecureImage fileId={student.avatar?student.avatar.id:null} className="cardImage" alt={Profile}/>

            <div className="trainerCard-Title">{student.firstName} {student.lastName}</div>

            <div className="trainerCard-card-grade">
                {getGrade(student)}
            </div>
        </>
    )
}

export default function SelectableStudentList({ students, selectHandler, preselected, grades }) {

    const [filteredStudents, setFilteredStudents] = useState(students.sort(sort_by_current_grade))
    const [allStudents, setAllStudents] = useState(students.sort(sort_by_current_grade))

    let g = [...grades]
    const [allGrades, setAllGrades] = useState(g.sort((a, b) => { return a.rank > b.rank ? 1 : -1 }))


    function select(userId, selected) {
        selectHandler(userId, selected);
    };

    function isPreselected(student) {
        var i;
        for (i = 0; i < preselected.length; i++) {
            if (preselected[i].id === student.id) {
                return true;
            }
        }
        return false;
    }



    function filter_grade(grade) {
        var s = []
        if (grade === null) {
            s = allStudents;
        } else {
            for (var student of allStudents) {

                if (student.current_grade && student.current_grade.id === grade.id) {
                    s.push(student)
                }
            }
        }

        setFilteredStudents(s);
    }

    function Filter() {
        return (
            <div className='sudent_list_commands'>
                <button className="btn btn-primary" onClick={e => filter_grade(null)}>
                    all
                </button>
                {allGrades.map(g => {
                    return (
                        <button key={`fbtn_${g.id}`} className="btn btn-primary" onClick={e => filter_grade(g)}>
                            {g.description}
                        </button>
                    )
                })}
            </div>
        )
    }

    return (
        <Fragment>
            <Filter />
            <div className="card_list">
                
                {filteredStudents.map(student => (
                    <Selectable key={student.id} 
                    component={<Student student={student} />}
                    selectionToggleCallback={select}
                    preSelected={() => isPreselected(student)}
                    id={student.id} />
                ))}
            </div>
        </Fragment>
    );

}
