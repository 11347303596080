import { createSlice } from '@reduxjs/toolkit'

export const currentFeatureSlice = createSlice({
    name:'features',
    initialState:{
        features:{},
        loadedDojoFeatures:false,
        loadedFeatures:false,
    },
    reducers:{
        setFeatures:(state,action)=>{
            state.features=action.payload
        },
        addFeatures:(state,action)=>{
            state.features = {...state.features,...action.payload}
        },
        setFeaturesLoaded:(state,action)=>{
            state.loadedFeatures=action.payload
        },
        setDojoFeaturesLoaded:(state,action)=>{
            state.loadedDojoFeatures=action.payload
        }
    }
})


export const {setFeatures,addFeatures,setFeaturesLoaded,setDojoFeaturesLoaded}= currentFeatureSlice.actions
export default currentFeatureSlice.reducer