import React, { Fragment } from 'react';
import Header from '../components/header';



export default function Dataprotection() {

    return <Fragment>
        <div className='splash-container'>
            <Header />
            <div className="dm-container">
                <div className="dm-container">
                    <div className='content-block'>
                        <h1>Data Protection</h1>
                        <h2>General collection:</h2>
                        <p>
                        We only collect data required for the operation of this service. This only includes data actively and purposefully provided by the user. Data is not shared with any third party except as stated under “Payment Information”. As required by GDPR please contact support@dojo-trainer.com if you wish your user profile to be deleted from our servers. In addition, we store basic logging information such as date and access IP address. None of which can be directly linked to your user profile but is a technical necessity. 
                        </p>
                        <h2>Payment Information</h2>
                        <p>
                            <ul>
                                <li>We do not store payment information ourselves. </li>
                                <li>We use an external provider <a href="https://stripe.com/">Stripe.com</a> for payment processing. See their <a href="https://stripe.com/en-at/privacy" >privacy policy</a></li>
                                <li>We store information linking our customers to the stripe customers for synchronising subscriptions.</li>
                            </ul>
                        </p>
                        <h2>Third party data:</h2>
                        <p>
                            The user of this platform states that they have the permission of their customers (trainers/students) to process their data on this platform (name, e-mail, training related data such as participation)
                        </p>

                        <h2>Right of revocation:</h2>
                        <p>
                         
You can revoke your consent to the collection and use of personal data at any time with effect for the future. The easiest way to do this is to send us an e-mail to the address support@dojo-trainer.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}

