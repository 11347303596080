import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import DisciplineIcon from '../assets/icons/discipline.svg';
import EditIcon from '../assets/icons/edit_pen.svg';
import axios from 'axios';
import config from 'react-global-configuration';
import { Text } from '../containers/Language';
import { Can } from '../security/can';
import DisciplineOBJ from '../DataClasses/Discipline';
import DisciplineCalendar from '../components/DisciplineCalendar';
import { enabled } from '../components/features';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toggleDirty } from '../storeSlices/sessionsSlice';
import { SecureImage } from '../components/SecureImage';


const CREATE_SESSION = gql`
mutation CreateSession($input: TrainingSessionInput!) {
createSession(input:$input){
    trainingSession{
      id
    }
  }
}
`;




const GET_DISCIPLINE_DETAILS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        dojo{
          id,
          name
        },

      }
    }
`;


export const SAVEURL = gql`
mutation UpdateDiscipline($id:Int!,$input: DisciplineInput!) {
  updateDiscipline(id:$id,input:$input){
    discipline{
      id,
      name,
      icon{
        id
      }
    }
  }
}

`;


export default function Discipline() {

  const [newSession, setNewSession] = React.useState(false);
  const [newSessionId, setNewSessionId] = React.useState(0);

  const dispatch = useDispatch()


  const [disciplineMutation] = useMutation(
    SAVEURL
  );

  function updateState(sessionId) {
    setNewSessionId(sessionId);
    setNewSession(true);
  }

  const [create] = useMutation(
    CREATE_SESSION,
    {
      onCompleted: (result) => {
        dispatch(toggleDirty())
        updateState(result.createSession.trainingSession.id);
      }
    }
  );



  function createNewSession() {
    const input = {
      disciplineId: disciplineId
    }
    create({ variables: { input: input } });


  }





  let { disciplineId } = useParams();
  disciplineId = parseInt(disciplineId);
  const { data, loading, error } = useQuery(
    GET_DISCIPLINE_DETAILS,
    { variables: { disciplineId } },
  );


  function addTopicNav() {

    if (enabled("session_topics")) {
      return (<Can I="edittopics" on={new DisciplineOBJ(disciplineId)}>
        <Link className="btn btn-primary" to={`/discipline/topics/${disciplineId}`} ><Text tid="ManageTopics" /></Link>
      </Can>);
    }

  }


  const onChangeHandler = event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);

    var axiosInst = axios.create({
      url: config.get('uploadUrl'),
      method: "post",
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: "JWT " + localStorage.getItem('token'),
      },
    })

    axiosInst.put(config.get('uploadUrl'), data).then(function (response) {
      saveIcon(response.data.id);
    }).catch(function (error) {
      console.log(error);
    });
  }

  function saveIcon(id) {

    var disciplineInput = {
      name: discipline.name,
      iconId: id,
    }


    disciplineMutation({ variables: { id: discipline.id, input: disciplineInput } });
  }

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const discipline = data.discipline;

  if (discipline === null) {
    return (<Navigate to={`/landing`} />);
  }

  if (newSession) {
    return (<Navigate to={`/session/${newSessionId}/edit`} />);
  } else {
    return (
      <Fragment>
        <Can I="view" this={new DisciplineOBJ(discipline.id)}>
          <div className="dm-container">

            <div className='content-block'>

              <div className="form-group files disciplineImg">
                <label htmlFor="file-input" className="disciplineImg">
                  <div className="avatarEditContainer disciplineImg">
                    <SecureImage fileId={discipline.icon?discipline.icon.id:null} className="disciplineImage" alt={DisciplineIcon}/>
                    <Can I="startsession" this={new DisciplineOBJ(discipline.id)}>
                      <div className="avatarEditOverlay">
                        <img src={[EditIcon]} style={{ width: '40px' }} alt="edit" />
                      </div>
                    </Can>
                  </div>
                </label>
                <Can I="startsession" this={new DisciplineOBJ(discipline.id)}>
                  <input type="file" id="file-input" className="form-control" multiple="" onChange={onChangeHandler} style={{ display: "none" }} />
                </Can>
              </div>


              <h2 className="containercentered mb-3">{discipline.name}</h2>



            </div>


            <div className='content-block action-block'>
              <Can I="startsession" this={new DisciplineOBJ(discipline.id)}>
                <button type="button" className="btn btn-primary" onClick={createNewSession}><Text tid="StartSession" /></button>
              </Can>



              <Link className="btn btn-primary" to={`/discipline/students/${discipline.id}`}><Text tid="Students" /></Link>
              <Link className="btn btn-primary" to={`/discipline/trainers/${discipline.id}`}><Text tid="Trainers" /></Link>



              <Link className="btn btn-primary" to={`/discipline/exams/${discipline.id}`} >
                <Text tid="Exams" />
              </Link>

              <Can I="startexam" on={new DisciplineOBJ(discipline.id)}>
                <Link className="btn btn-primary" to={`/discipline/grades/${discipline.id}`} >
                  <Text tid="GradeSystem" />
                </Link>
              </Can>
              {addTopicNav()}

            </div>

            <div className='content-block'>
              <h2 className='content-block-header'><Text tid="nextDates" /></h2>
              <DisciplineCalendar disciplines={[discipline.id]} />
            </div>
          </div>
        </Can>
      </Fragment>
    );
  }
}

