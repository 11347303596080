import React, { Fragment } from 'react';
import Header from '../components/header';


export default function Impressum() {

    return <Fragment>
        <div className='splash-container'>
            <Header />
            <div className="dm-container">
                <div className="dm-container">
                    <div className='content-block'>
                        <h1>Impressum</h1>
                        <p>
                            <h3>Betreiber der seite Dojo-Trainer.com:</h3><br />
                            Dr. Benedict Wright e.U.<br />
                            Venusgasse 15, 9020 Klagenfurt<br />
                            Österreich <br />
                            <br />
                            E-Mail: office@digitalwright.eu<br />
                            <br />
                            UID-Nummer: ATU76263428<br />
                            Zuständige Kammer: Wirtschaftskammer Kärnten<br />
                            Gewerbe: Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik (§ 5 Abs. 2 GewO 1994)<br />
                            <br />
                            Verantwortlich für den Inhalt dieses Online-Angebots:<br />
                            Dr. Benedict Wright e.U.<br />
                            E-Mail: office@digitalwright.eu<br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}

