import React, { useState } from 'react';
import { Text } from '../containers/Language';
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag';

const RESET = gql`
mutation ResetPassword($email:String!){
  resetPassword(email:$email){
   ok
  }
}`;

function ResetDialog() {

  const [showReset, setShowReset] = useState(false)
  const [mail, setMail] = useState("")

  const [resetMutation] = useMutation(
    RESET
  )

  function reset_pw() {
    resetMutation({
      variables: { email: mail },
      onCompleted: () => {setShowReset(false);}
    })

  }

  function onChangeMail(event) {
    const m = event.target.value;
    setMail(m)
  }

  if (showReset) {
    return (
      <div className='delete_user_dialog'>
        <div className='delete_user_dialog_inner'>
          <div><Text tid="Reset_pw" />  ?</div>
          <input
            className="form-control"
            required
            type="email"
            name="email"
            placeholder="Email"
            data-testid="email-input"
            onChange={onChangeMail}
          />
          <div className="delete_user_dialog_inner_action_bar">
            <button className="btn btn-primary m-2" onClick={() => setShowReset(false)}><Text tid="cancel" /></button>
            <button className="btn btn-primary m-2" onClick={() => reset_pw()}><Text tid="yes" /></button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>Forgot Password? <a href="#" onClick={e => setShowReset(true)}>reset</a></div>
    )
  }

}
//TODO Refactor toi be functional to be able to properly translate
export default function LoginForm({ login }) {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")


  function onChangeUsername(event) {
    const uname = event.target.value;
    setUsername(uname)
  };

  function onChangePassword(event) {
    const pw = event.target.value;
    setPassword(pw)
  };

  function onSubmit(event) {
    event.preventDefault();
    login({ variables: { username: username, password: password } });

  };

  function show_reset() {

  }


  return (
    <div className="login_form">
      <form onSubmit={onSubmit}>
        <div className="row mb-3">
          <input
            className="form-control"
            required
            type="username"
            name="username"
            placeholder="Username"
            data-testid="username-input"
            onChange={onChangeUsername}
          />
        </div>
        <div className="row mb-3">
          <input
            className="form-control"
            required
            type="password"
            name="password"
            placeholder="password"
            data-testid="password-input"
            onChange={onChangePassword}
          />
        </div>
        <div className="row">
          <button type="submit" className="btn btn-primary mb-3" >Log in</button>
        </div>

      </form>
      <ResetDialog />
      <div>Not a user yet? <a href="/register">Register</a></div>
    </div>
  );

}
