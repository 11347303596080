import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../containers/Language';
import { Link, useLocation } from "react-router-dom";
import RobeSolid from '../assets/icons/robe-solid.svg';
import logo from '../assets/logo_weiss_mit_schrift.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faClipboard,
    faUser,
    faToriiGate,
    faQuestion

} from "@fortawesome/free-solid-svg-icons";

export default function Sidebar(props) {

    function uniqBy(a, key) {
        var seen = {};
        return a.filter(function (item) {
            var k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        })
    }

    const userId = useSelector((state) => state.currentUser.id)
    const teaches = useSelector((state) => state.currentUser.teaches)
    const studies = useSelector((state) => state.currentUser.studies)
    var allDisciplines = teaches;
    allDisciplines = uniqBy(allDisciplines.concat(studies), JSON.stringify)
    const isAdmin = useSelector((state) => state.currentUser.isAdmin)
    const dojoId = useSelector((state) => state.currentUser.dojoId)
    const location = useLocation();


    function getActive_style(link_location) {
        if (location.pathname === link_location) {
            return 'activeLink'
        } else {
            return ''
        }
    }

    function isDisciplieSub(disciplineId) {
        let students_link = "/discipline/students/" + disciplineId
        let trainer_link = "/discipline/trainers/" + disciplineId
        let exam_link = "/discipline/exams/" + disciplineId
        let grades_link = "/discipline/grades/" + disciplineId
        let topics_link = "/discipline/topics/" + disciplineId
        let discipline_link = "/discipline/" + disciplineId

        if (location.pathname === students_link
            || location.pathname === trainer_link
            || location.pathname === exam_link
            || location.pathname === grades_link
            || location.pathname === topics_link
            || location.pathname === discipline_link) {
            return true
        }
        return false
    }


    function getManageDojoLink(id) {
        let dojo_link = "/dojo/" + id
        let members_link = "/members/" + id
        let subscriptions_link = "/subscriptions/" + id

        if (location.pathname === dojo_link
            || location.pathname === members_link
            || location.pathname === subscriptions_link) {
            return 'activeLink'
        } else {
            return ''
        }
    }

    function getdisciplineSubStyle(disciplineId) {

        if (isDisciplieSub(disciplineId)) {
            return 'activeLink'
        } else {
            return ''
        }
    }

    function close_nav() {
        document.getElementById("nav_opener").checked=false;
    }
    return (
        <Fragment>

            <nav className='main_nav'>
                <input type="checkbox" id="nav_opener" />
                <label className="main_nav_opener" htmlFor="nav_opener">

                </label>
                <ul className='main_nav_list'>
                    <li className='logo_li'>
                        <img className='sidebar_logo' src={logo} alt="Logo" />
                    </li>
                    <Link to="/landing" onClick={e => close_nav()} >
                        <li className={getActive_style('/landing') + " icon_li"}>
                            <FontAwesomeIcon icon={faClipboard} />
                            <Text tid="Dashboard" />
                        </li>
                    </Link>
                    <li>
                        <input type="checkbox" id="discipline_opener" />
                        <label className="main_nav_sub_opener" htmlFor="discipline_opener">
                            <img className="sidebar_icon" alt="discipline icon" src={RobeSolid} />
                            <Text tid="Disziplin" /><FontAwesomeIcon icon={faChevronDown} />
                        </label>

                        <ul id="discipline_inner" className='inner_list'>
                            {allDisciplines.map((t) => {
                                return (
                                    <Link onClick={e => close_nav()} key={`nav_${t.id}`} to={`/discipline/${t.id}`}>
                                        <li key={`discipline_${t.id}`} className={getdisciplineSubStyle(t.id) + " icon_li"}>
                                            {t.name}
                                        </li>
                                    </Link>
                                )
                            })}
                        </ul>

                    </li>
                    <Link onClick={e => close_nav()} to={`/person/${userId}/edit`}>
                        <li className={getActive_style(`/person/${userId}/edit`) + " icon_li"}>
                            <FontAwesomeIcon icon={faUser} />
                            <Text tid="Profile" />
                        </li>
                    </Link>
                    {isAdmin ?
                        <Link onClick={e => close_nav()} to={`/dojo/${dojoId}`}>
                            <li className={getManageDojoLink(dojoId) + " icon_li"}>
                                <FontAwesomeIcon icon={faToriiGate} />
                                <Text tid="ManageDojo" />
                            </li>
                        </Link>

                        : ""
                    }


                    <Link onClick={e => close_nav()} to={`/support`}>
                        <li className={getActive_style(`/support`) + " icon_li"}>
                            <FontAwesomeIcon icon={faQuestion} />
                            <Text tid="support" />
                        </li>
                    </Link>

                </ul>
            </nav>
        </Fragment>
    );
}

