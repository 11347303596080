import React, { Fragment } from 'react';
import { LanguageProvider } from '../containers/Language';
import { Text } from '../containers/Language';
import Features from '../components/features'
import { Link } from "react-router-dom";

export default function PageContainer(props) {

  return (
    <Fragment>
      <Features>
      
          <LanguageProvider>
            <div className="root-container">
              {props.children}
            </div>
            <footer className="footer-container">
              <span>Powered by: <a href="https://digitalwright.eu/">digitalwright.eu</a></span>
              <span><a href="/impressum"><Text tid="impressum" /></a></span>
              <span><a href="/dataprotection"><Text tid="dataprotection" /></a></span>
              <span><Link to="/contact"><Text tid="contact" /></Link></span>
            </footer>
          </LanguageProvider>
      </Features>
    </Fragment>
  );
}

