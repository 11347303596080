import React, { useState, Fragment } from 'react';
import { Text } from '../containers/Language';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { useSelector,useDispatch } from 'react-redux';
import { enabled } from '../components/features'
import  {setStudents} from '../storeSlices/currentUserSlice';
import { cloneDeep } from 'lodash';

export default function NewMemberForm({ callback }) {

    const [show, setShow] = useState(false)
    const [email, setMail] = useState("");
    const [emailError, setMailError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [userExistsError,setUserExistsError]=useState("");

    const [teaches, setTeaches] = useState([])

    const [requiresMail, setRequiresMail] = useState(false)

    const isAdmin = useSelector((state) => state.currentUser.isAdmin)
    const isTrainer = useSelector((state) => state.currentUser.isTrainer)
    const trainers = useSelector((state) => state.currentUser.teachers)
    var students = cloneDeep(useSelector((state) => state.currentUser.students))
    const dojoId = useSelector((state) => state.currentUser.dojoId)
    var disciplines = useSelector((state) => state.currentUser.disciplines)
    const trainer_disciplines = useSelector((state) => state.currentUser.teaches)

    const dispatch = useDispatch()

    if (!isAdmin) {
        disciplines = disciplines.filter(d => {
            return trainer_disciplines.some(dis => dis.id === d.id);
        })
    }
    var s = []
    if (disciplines.length === 1 && !isAdmin && isTrainer) {
        s = [disciplines[0]]
    }

    const [studies, setStudies] = useState(s)

    const ud = {}

    disciplines.map(d => {
        if (d.grades.length > 0) {
            ud[d.id] =-1
        }
    })


    const [userGrades, setUserGrades] = useState(ud)

    function canAddTrainer() {
        return (enabled("unlimited_trainers") || trainers.length < 1) && isAdmin
    }

    function canAddStudents() {
        return (enabled("unlimited_students") || students.length < 50) && (isTrainer || isAdmin)
    }

    const languageContext = useContext(LanguageContext);

    const CREATE_MEMBER = gql`
    mutation CreatePerson($input: PersonInput!) {
    createPerson(input:$input){
        person{
            id,
            isTrainer,
            isDojoleader,
            firstName,
            lastName,
            avatar{
              id
            },
        }
      }
    }
    `;

    const [create, { loading, error }] = useMutation(
        CREATE_MEMBER,
        {
            onCompleted(create) {
                students.push(create.createPerson.person)
                dispatch(setStudents(students))
                if (callback) {
                    callback();
                }

            }
        }
    );

    function onToggleTeaches(event, discipline) {
        let t = [...teaches]

        if (event.target.checked) {
            t.push(discipline)
        } else {
            t = t.filter(function (value, index, arr) {
                return value !== discipline
            })
        }
        setRequiresMail(t.length > 0);
        setTeaches(t)
    }
    function onToggleStudies(event, discipline) {
        let t = [...studies]

        if (event.target.checked) {
            t.push(discipline)
        } else {
            t = t.filter(function (value, index, arr) {
                return value !== discipline
            })
        }
        setStudies(t)
    }

    function onChangeEmail(event) {
        const email = event.target.value;
        setMail(email);
    };

    function onChangeFirstname(event) {
        const firstname = event.target.value;
        setFirstName(firstname);
    };

    function onChangeLastname(event) {
        const lastname = event.target.value;
        setLastName(lastname);
    };

    function onChangeGrade(event, discipline) {
        let value = event.target.value;
        let u = userGrades
        u[discipline.id] = value
        setUserGrades(u)
        console.log(u)
    }

    function getExistingUser(){
        for(let student of students){
            if(student.firstName === firstName.trim() && student.lastName===lastName.trim()){
                return student
            }
        }
    }

    function validate() {
        let valid = true;
        if (email === "" && requiresMail) {
            setMailError("invalidEmail");
            valid = false;
        }
        if (firstName === "") {
            setFirstNameError("invalidFirstName")
            valid = false
        }
        if (lastName === "") {
            setLastNameError("invalidLastName")
            valid = false
        }
        let user = getExistingUser()
        if(user){
            setUserExistsError("userExists")
            
            valid = false
        }
        return valid;
    }

    function createNewMember() {
        if (validate()) {
            let t = teaches.map(t => {
                return { "did": t.id, "gid": userGrades[t.id] }
            })
            let s = studies.map(s => {
                return { "did": s.id, "gid": userGrades[s.id] }
            })

            var personInput = {
                dojoId: dojoId,
                firstname: firstName,
                lastname: lastName,
                username: teaches.length > 0 ? email : "",
                email: teaches.length > 0 ? email : "",
                password: "",
                isTrainer: teaches.length > 0,
                teaches: JSON.stringify(t),
                studies: JSON.stringify(s)
            }

            create({ variables: { input: personInput } });
            setFirstName("")
            setLastName("")
            setMail("")

            setShow(false);
        }

    }



    if (!show) {
        if (canAddStudents()) {
            return (
                <button className="btn btn-primary" onClick={e => setShow(true)}><Text tid="CreateNewMember" /></button>
            )
        } else {
            return ("")
        }
    } else {
        return (
            <div className='dialog_card'>
                <div className='dialog_card_inner'>
                    {requiresMail ?
                        <input
                            className="form-control mt-2"
                            required
                            type="email"
                            name="email"
                            placeholder="trainer@email.com"
                            data-testid="email-input"
                            onChange={onChangeEmail}
                        /> : ""}
                    <div className='error'><Text tid={emailError} /></div>
                    <input
                        className="form-control mt-2"
                        required
                        type="text"
                        name="firstname"
                        placeholder={languageContext.dictionary["Firstname"] || "Firstname"}
                        data-testid="firstname-input"
                        onChange={onChangeFirstname}
                        value={firstName}
                    />
                    <div className='error'><Text tid={firstNameError} /></div>
                    <input
                        required
                        className="form-control mt-2"
                        type="text"
                        name="lastname"
                        placeholder={languageContext.dictionary["Lastname"] || "Lastname"}
                        data-testid="lastname-input"
                        onChange={onChangeLastname}
                        value={lastName}
                    />
                    <div className='error'><Text tid={lastNameError} /></div>
                    <div className='error'><Text tid={userExistsError} /></div>

                    <div className='add_members_disciples'>
                        <div></div>
                        <div className='add_members_disciplines_header'>
                            {canAddTrainer() ? <Text tid="Trainer" /> : ""}
                        </div>
                        <div className='add_members_disciplines_header'>{disciplines.length > 1 ||canAddTrainer()  ? <Text tid="Student" /> : ""}</div>
                        <div className='add_members_disciplines_header'><Text tid="Grade" /></div>

                        {disciplines.map(d => {
                            return (
                                <Fragment key={d.id}>
                                    <div className='add_members_disciplines_header'>{d.name}</div>
                                    <div>
                                        {canAddTrainer() ? <input type="checkbox" onClick={e => onToggleTeaches(e, d)} /> : ""}
                                    </div>
                                    <div>
                                        {disciplines.length > 1 ||canAddTrainer()  ? <input type="checkbox" onClick={e => onToggleStudies(e, d)} /> : ""}
                                    </div>
                                    <div>
                                        {
                                            d.grades.length > 0 ?
                                                <select className='form-select' onChange={e => onChangeGrade(e, d)} defaultValue={-1}>
                                                    <option key={`nograde_${d.id}`} value="-1">{languageContext.dictionary["NoGrade"] || "No grade"}</option>
                                                    {
                                                        d.grades.map((g, i) => {
                                                            return (<option key={`${g.id}${d.id}`} value={g.id}>{g.description}</option>)
                                                        })
                                                    }
                                                </select>
                                                : ""
                                        }

                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                    <button className="btn btn-primary m-2" onClick={() => createNewMember()}><Text tid="CreateNewMember" /></button>
                    <button className="btn btn-primary m-2" type="cancel" onClick={() => setShow(false)}><Text tid="Cancel" /></button>
                </div>
            </div>
        )
    }


}