import React, { Fragment } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams, Link } from "react-router-dom";
import { Text } from '../containers/Language';
import RemoveImage from '../assets/icons/remove.svg';
import AddImage from '../assets/icons/add.svg';
import Img from 'react-image'
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import EditableLabel from '../components/editable-label';
import DisciplineOBJ from '../DataClasses/Discipline';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../security/can';
import { Can } from '../security/can';
import { Navigate } from "react-router-dom";
import { enabled } from '../components/features';

const GET_DISCIPLINE_TOPICS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        categories{
            name,
            id,
            topics{
              name,
              id
            }
        },
        dojo{
            name,
            id
        }
      },
    }
`;

const CREATE_TOPIC = gql`
mutation CreateTopic($name: String!, $categoryId: Int!) {
createTopic(name:$name, categoryId:$categoryId){
    topic{
      id,
      name
    }
  }
}
`;


const DELETE_TOPIC = gql`
mutation DELETETopic($id: ID!) {
deleteTopic(id:$id){
    ok
  }
}
`;


const CREATE_CATEGORY = gql`
mutation CreateTopic($name: String!, $disciplineId: Int!) {
createCategory(name:$name, disciplineId:$disciplineId){
    category{
      id,
      name
    }
  }
}
`;

const DELETE_CATEGORY = gql`
mutation DeleteCategory($id: ID!) {
deleteCategory(id:$id){
    ok
  }
}
`;

const UPDATE_CATEGORY = gql`
mutation UpdateCategory($id: ID!, $name:String!) {
updateCategory(id:$id, name:$name){
    ok
  }
}
`;

const UPDATE_TOPIC = gql`
mutation UpdateTopic($id: ID!, $name:String!, $categoryId:Int!) {
updateTopic(id:$id, name:$name, categoryId:$categoryId){
    ok
  }
}
`;

export default function DisciplineTopics() {
  const languageContext = useContext(LanguageContext);
  const { disciplineId } = useParams();

  const [topicName, setTopicName] = React.useState("");
  const [categoryId, setCategory] = React.useState(-1);
  const [catName, setCatName] = React.useState("");


  const { data, loading, error } = useQuery(
    GET_DISCIPLINE_TOPICS,
    { variables: { disciplineId } },
  );


  const [deleteTopic] = useMutation(
    DELETE_TOPIC,
    {
      refetchQueries: [
        {
          query: GET_DISCIPLINE_TOPICS,
          variables: { disciplineId },
        },
      ]
    }
  );


  const [deleteCategory] = useMutation(
    DELETE_CATEGORY,
    {
      refetchQueries: [
        {
          query: GET_DISCIPLINE_TOPICS,
          variables: { disciplineId },
        },
      ]
    }
  );


  const [editCategoryMutation] = useMutation(
    UPDATE_CATEGORY
  )
  const [editTopicMutation] = useMutation(
    UPDATE_TOPIC
  )

  function remove(topicId) {
    deleteTopic({ variables: { id: topicId } });
  }

  function remove_category(categoryId) {
    deleteCategory({ variables: { id: categoryId } });
  }

  const [createTopic, { loadingCreateTopic, errorCreateTopic }] = useMutation(
    CREATE_TOPIC,
    {
      refetchQueries: [
        {
          query: GET_DISCIPLINE_TOPICS,
          variables: { disciplineId },
        },
      ]
    }
  );


  const [createCategory, { loadingCreateCategory, errorCreateCategory }] = useMutation(
    CREATE_CATEGORY,
    {
      refetchQueries: [
        {
          query: GET_DISCIPLINE_TOPICS,
          variables: { disciplineId },
        },
      ]
    }
  );

  function addNew() {
    if (topicName !== "" && categoryId !== -1) {
      createTopic({ variables: { name: topicName, categoryId: categoryId } });
    }
  }

  function addNewCat() {
    if (catName !== "") {
      createCategory({ variables: { name: catName, disciplineId: disciplineId } })
    }
  }

  function editTopic(value, props) {
    editTopicMutation({ variables: { id: props.id, name: value, categoryId: props.categoryId } });
  }

  function editCategory(value, props) {
    editCategoryMutation({ variables: { id: props, name: value } });
  }



  const ability = useAbility(AbilityContext);

  if (loading || loadingCreateCategory || loadingCreateTopic) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (errorCreateCategory) return <p>ERROR: {errorCreateCategory.message}</p>;
  if (errorCreateTopic) return <p>ERROR: {errorCreateTopic.message}</p>;



  const discipline = data.discipline;


  if (discipline === null || !enabled("session_topics")) {
    return (<Navigate to={`/landing`} />);
  }

  const categories = discipline.categories;


  function renderTopic(topic, catID) {
    return (
      <div key={topic.id} className="session-row">
        <EditableLabel label={topic.name} canEdit={ability.can('edittopics', new DisciplineOBJ(discipline.id))} props={{ id: topic.id, categoryId: catID }} callback={editTopic} />

        <Can I="edittopics" on={new DisciplineOBJ(discipline.id)}>
          <button type="button" onClick={() => remove(topic.id)} className="btn"><Img className="btnIcon-small remove-icn" src={[RemoveImage]} /></button>
        </Can>
      </div>
    );
  }

  function renderCategory(category) {
    return (
      <div key={category.id} className="dm-container">
        <div className="session-row">
          <h4 className="categoryName">
            <EditableLabel label={category.name} canEdit={ability.can('edittopics', new DisciplineOBJ(discipline.id))} props={category.id} callback={editCategory} />
          </h4>
          <Can I="edittopics" on={new DisciplineOBJ(discipline.id)}>
            <button type="button" onClick={() => remove_category(category.id)} className="btn"><Img className="btnIcon-small remove-icn" src={[RemoveImage]} /></button>          </Can>
        </div>
        <div className="dm-container">
          {category.topics.map(topic => renderTopic(topic, category.id))}
        </div>
      </div>

    );
  }

  return (
    <Fragment>
      <div className="dm-container">
        <Link className='back' to={`/discipline/${discipline.id}`} >
          <Text tid="backto" /> {discipline.name}
        </Link>

        <div className='content-block'>
          <h2 className='content-block-header'>{discipline.name} <Text tid="Topics" /></h2>
          {categories.map(category => renderCategory(category))}
        </div>
        <Can I="edittopics" on={new DisciplineOBJ(discipline.id)}>

          <div className='content-block'>
            <h2 className='content-block-header'><Text tid="NewCategory" /></h2>
            <div className="row mb-1 mt-3">
              <div className="col">
                <input type="text" className="form-control" name="catname" id="catname" placeholder={languageContext.dictionary["catName"] || "catName"} onChange={e => setCatName(e.target.value)} />
              </div>
              <div className="col">
                <button type="button" onClick={() => addNewCat()} className="btn"><Img className="btnIcon-small" src={[AddImage]} /></button>
              </div>
            </div>
          </div>

          <div className='content-block'>
            <h2 className='content-block-header'><Text tid="NewTopics" /></h2>
            <div className="row mb-1 mt-3">
              <div className="col">
                <input type="text" className="form-control" name="name" id="name" placeholder={languageContext.dictionary["topicName"] || "topicName"} onChange={e => setTopicName(e.target.value)} />
              </div>
              <div className="col">
                <select className="form-select" onChange={e => setCategory(e.target.value)}>
                  <option value="">{languageContext.dictionary["SelectCategory"] || "SelectCategory"}</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <button type="button" onClick={() => addNew()} className="btn"><Img className="btnIcon-small" src={[AddImage]} /></button>
              </div>
            </div>
          </div>

        </Can>
      </div>
    </Fragment>
  );

}