import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import DisciplineCard from '../components/discipline-card';
import { Text } from '../containers/Language';
import { Link } from "react-router-dom";
import { cloneDeep } from 'lodash';
import { Can } from '../security/can'
import { useSelector } from 'react-redux';
import DisciplineCalendar from '../components/DisciplineCalendar';



export const DISCIPLINE_DATA = gql`
fragment discipline_on_landing on DisciplineType{
    __typename,
    name,
    id,
    icon{
        id
    },
    dojo{
      id,
    },
  }
`;

export const USER_DATA = gql`
fragment landing_user on PersonType{
       __typename,
        firstName,
        lastName,
        isTrainer,
        isDojoleader,
        isSuperuser,
        id,
        avatar{
            file
        }
}
${DISCIPLINE_DATA}
`;

export const GET_CURRENT_USER = gql`
query CurrentUser{
  currentUser{
        ...landing_user,
        teaches{
            ...discipline_on_landing
        },
        studies{
            ...discipline_on_landing
        },
        dojo{
            name,
            id,
            disciplines{
                id
            }
        },
        grades{
            id,
            description,
            discipline{
                id
            }
        }
    }
}
${USER_DATA}
${DISCIPLINE_DATA}
`;


function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i]['id'] === obj['id']) {
            return true;
        }
    }

    return false;
}

function get_grade_name(grades, d_id) {
    for (let element of grades) {
        if (parseInt(element.discipline.id) == d_id) {
            return element.description;

        }
    }
}


export default function Landing() {

    const dojoId = useSelector((state) => state.currentUser.dojoId)

    const { data, loading, error } = useQuery(
        GET_CURRENT_USER,
    );


    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;
    const currentUser = data.currentUser;

    let teaches = cloneDeep(currentUser.teaches);
    let studies = cloneDeep(currentUser.studies.filter(obj => !containsObject(obj, teaches)));
    let grades = currentUser.grades;
    teaches.forEach(element => {
        element.current_grade = get_grade_name(grades, element.id);
    });

    studies.forEach(element => {
        element.current_grade = get_grade_name(grades, element.id);
    });

    function DisciplineHelper({ disciplines }) {
        if (disciplines.length === 0) {
            return (
                <div>
                    <h2><Text tid="NoDisciplines" /></h2>

                    <Can I="add" a="discipline">
                        <Link to={`/dojo/${dojoId}`}><Text tid="CreateDisciplines" /></Link>
                    </Can>

                </div>
            )
        } else {
            return (<>

            </>);
        }
    }
    let disciplines = currentUser.studies.map((d) => parseInt(d.id)).concat(currentUser.teaches.map((d) => parseInt(d.id)))
    return (
        <Fragment>

            <div className="dm-container">
                <div className='content-block'>
                    <h2 className='content-block-header'><Text tid="myDisciplines" /></h2>
                    <DisciplineHelper disciplines={currentUser.dojo.disciplines} />
                    <div className="discipline-row">
                        {teaches.map(discipline => (
                            <DisciplineCard key={discipline.id} discipline={discipline} clicktarget='discipline' foruser='' />
                        ))}
                        {studies.map(discipline => (
                            <DisciplineCard key={discipline.id} discipline={discipline} clicktarget='discipline/statistics' foruser={currentUser.id} />
                        ))}
                    </div>
                </div>

                <div className='content-block'>
                    <h2 className='content-block-header'><Text tid="nextDates" /></h2>
                    <DisciplineCalendar disciplines={disciplines} />
                </div>
            </div>
        </Fragment>
    );

}



