import React, { useContext, useState } from 'react';
import Select from 'react-select';
import Img from 'react-image'
import RemoveImage from '../assets/icons/remove.svg';
import { LanguageContext } from '../containers/Language';
import { Text } from '../containers/Language';

export default function Participant({ student, grades, grade, target_grade, update_callback, delete_callback, editable, payed, certificate }) {

    const [target, setTarget] = useState(target_grade)
    const [_grade, setGrade] = useState(grade)
    const [_payed, setPayed] = useState(payed?payed:false)
    const [_certificate, setCertificate] = useState(certificate?certificate:false)

    function handleSetTarget(val) {
        setTarget(val)
        update_callback(student, _grade, val,_certificate,_payed)

    }

    function handleSetGrade(val) {
        setGrade(val)
        update_callback(student, val, target,_certificate,_payed)

    }

    function handleSetPayed(checked) {
        setPayed(checked)
        update_callback(student, _grade, target,_certificate,checked)
    }

    function handleSetCertificate(checked) {
        setCertificate(checked)
        update_callback(student, _grade, target,checked,_payed)
    }



    function handelDelete() {
        delete_callback(student)
    }


    const languageContext = useContext(LanguageContext);
    const grade_id = _grade ? _grade.id : -1;
    var s = grades.filter(g => g.id === grade_id)
    s = s ? s[0] : null;


    if (editable) {
        return (
            <>
                <div className="">
                    {student.firstName} {student.lastName}
                </div>

                <div className="">
                    <Select
                        options={grades}
                        placeholder={languageContext.dictionary["selectTargetGrade"] || "selectTargetGrade"}
                        getOptionLabel={option =>
                            `${option.description}`
                        }
                        defaultValue={target}
                        className="react-selectcomponent"
                        onChange={handleSetTarget}
                    />
                </div>


                <div className="">
                    <Select
                        options={grades}
                        placeholder={languageContext.dictionary["selectGrade"] || "selectGrade"}
                        defaultValue={s ? s : null}
                        getOptionLabel={option =>
                            `${option.description}`
                        }
                        className="react-selectcomponent"
                        onChange={handleSetGrade}
                    />
                </div>



                <div className="">
                    <input type="checkbox" defaultChecked={_certificate} onClick={e => handleSetCertificate(e.target.checked)} />
                </div>
                <div className="">
                    <input type="checkbox" defaultChecked={_payed} onClick={e => handleSetPayed(e.target.checked)} />
                </div>
                <div className="">
                    <button className="btn btn-primary" onClick={handelDelete}>
                        <Img src={[RemoveImage]} className="btnIcon-small" />
                    </button>
                </div>
            </>
        );
    }
    else {
        return (
            <>
                <div className="">
                    {student.firstName} {student.lastName}
                </div>

                <div className="">
                    {target ? target.description : ""}
                </div>
                <div className="">
                    {s ? s.description : ""}
                </div>

                <div className="">
                    {_certificate ? <Text tid="yes" /> : <Text tid="no" />}
                </div>
                <div className="">
                    {_payed ? <Text tid="yes" /> : <Text tid="no" />}
                </div>

                <div>

                </div>
            </>
        );
    }
}

