import React, { useState } from 'react';
import { Text } from '../containers/Language';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { useParams, Link } from "react-router-dom";
import { Loading } from '../components';
import Cookies from 'js-cookie'
import config from 'react-global-configuration';

const GET_SUBSCRIPTION_AND_PRODUCTS = gql`
query Dojo($dojoId: ID!){
  dojo(id: $dojoId){
        subscription{
          stripeSubscriptionId,
          status,
          validUntil
          product{
              id,
              stripeProductName
          }
        }
  }
}
`;



function CurrentSub({ subscription }) {


    if (subscription === null) {
        return (

            <div className='subscription_row'>
                <div className='subscription_title'><Text tid="productName" /></div>
                <div className='subscription_title'><Text tid="active" /></div>
                <div className='subscription_title'><Text tid="validUntil" /></div>

                <div>Dojo-Trainer Free</div>
                <div>Active</div>
                <div>-</div>
            </div>

        )
    }


    let d = new Date(Date.parse(subscription.validUntil))
    return (
        <div className='subscription_row'>

            <div className='subscription_title'><Text tid="productName" /></div>
            <div className='subscription_title'><Text tid="active" /></div>
            <div className='subscription_title'><Text tid="validUntil" /></div>


            <div>{subscription.product.stripeProductName}</div>
            <div>{subscription.status}</div>
            <div>{d.toLocaleDateString()}</div>
        </div>
    )
}



export default function Subscriptions() {
    const { dojoId } = useParams();
    const [isLoading, setLoading] = useState(false);


    const { data, loading, error} = useQuery(
        GET_SUBSCRIPTION_AND_PRODUCTS,
        { variables: { dojoId } },
    );



    function manageSubscriptions() {
        setLoading(true)
        //fetch session url and redirect
        const url = config.get('baseurl');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: "JWT " + localStorage.getItem("token"),
                "X-CSRF-Token": Cookies.get("csrftoken"),
            },
            body: JSON.stringify(
                {
                    "init": true,
                }
            )
        };


        fetch(`${url}init_billing_portal/`, requestOptions).then(function (response) {
            return response.json()
        }).then(function (json) {
            if (!json["error"] && json["sessionurl"]) {
                window.location.replace(json["sessionurl"]);
            }
        });
    }


    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;


    const subscription = data.dojo.subscription



    return (
        <>
            {isLoading ? <Loading /> : ""}
            <div className="dm-container">
            <Link className='back' to={`/dojo/${dojoId}`} >
                <Text tid="backto" /> <Text tid="ManageDojo" />
            </Link>
                <div className='content-block'>
                    <div className='current_sub_container'>
                        <h2><Text tid="CurrentSub" /></h2>
                        <CurrentSub subscription={subscription} />
                    </div>


                    <button className="btn btn-primary" type="submit" onClick={() => manageSubscriptions()}>
                        <Text tid="ManageSubscriptionTitle" />
                    </button>
                </div>
            </div>
        </>
    )
}