import React, { Fragment } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/client'
import { Loading } from '../components';
import { enabled } from '../components/features'
import gql from 'graphql-tag';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import { Text } from '../containers/Language';
import Header from '../components/header';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";


export const REGISTER = gql`
mutation register($input:RegisterInput!){
  register(input:$input){
   ok
  }
}`;


export default function Register() {
  const { product } = useParams();
  let navigate = useNavigate();

  const [register, { loading, error }] = useMutation(
    REGISTER,
    {
      onCompleted({ data }) {
        navigate("/login")
      }
    }
  );

  const languageContext = useContext(LanguageContext);

  if (loading) return <Loading />;
  if (error) return <p>An error occurred: {error.message}</p>;

  if (!enabled("registration")) {

    return (<Fragment>
      <div className='splash-container'>
        <Header />
        <div className="dm-container">
          <Text tid="regdisabled" />
        </div>
      </div>
    </Fragment>
    )
  }
  return (<Fragment>
    <div className='splash-container'>
      <Header />
      <div className="dm-container">
        <div className='spash_container'>
          <div className='register-content-block'>
            <div>
              <h2><Text tid="register" /></h2>
            </div>
            <Formik
              initialValues={{ email: '', dojo: '', fname: '', lname: '' }}
              validate={values => {
                const errors = {};
                if (!values.dojo) {
                  errors.dojo = languageContext.dictionary["required"] || "required";;
                }
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = languageContext.dictionary["invalidEmail"] || "invalidEmail";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {

                var registerInput = {
                  firstname: values.fname,
                  lastname: values.lname,
                  email: values.email,
                  dojoName: values.dojo,
                  product: product
                }
                register({ variables: { input: registerInput } })
                setSubmitting(false);

              }}
            >
              {({ isSubmitting }) => (
                <Form className='register-from'>

                  <ErrorMessage name="dojo" />
                  <Field className="form-control mb-1" type="text" name="dojo" placeholder={languageContext.dictionary["DojoName"] || "DojoName"} />

                  <ErrorMessage name="fname" />
                  <Field className="form-control mb-1" type="text" name="fname" placeholder={languageContext.dictionary["Firstname"] || "Firstname"} />

                  <ErrorMessage name="lname" />
                  <Field className="form-control mb-1" type="text" name="lname" placeholder={languageContext.dictionary["Lastname"] || "Lastname"} />

                  <ErrorMessage name="email" />
                  <Field className="form-control mb-1" type="email" name="email" placeholder={languageContext.dictionary["Email"] || "Email"} />


                  <button className="btn btn-primary mt-2" type="submit" disabled={isSubmitting}>
                    register
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
  );
}