import React, { Fragment,  useState } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams, Link } from "react-router-dom";
import TrainerCard from '../components/trainer-card';
import { Text } from '../containers/Language';
import { Navigate } from "react-router-dom";
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { SecureImage } from "../components/SecureImage";
import NewMemberForm from '../components/new-member-form';
import { Can } from '../security/can';
import DisciplineOBJ from '../DataClasses/Discipline';

import RemoveIcon from '../assets/icons/remove.svg';
import Profile from '../assets/icons/profile.svg';
import { sort_by_current_grade } from '../logic/sorting';


export const PERSON_DATA = gql`
fragment person on PersonType{
  __typename,
   id,
   firstName,
   lastName,
   avatar{
     id
   },
   grades{
    id,
    description,
    discipline{
      id,
      grades{
        id,
        description,
        rank
      }
    },
    rank
  }
}
`;

export const GET_DISCIPLINE_STUDENTS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        dojo{
          id,
          name
        },
        students{
          edges{
            node{
            ...person
            }
          }
        }
      }
    }
    ${PERSON_DATA}
`;


const ADD_DISCIPLINE_TO_PERSON = gql`
mutation AddPersonDiscipline($personId: Int!, $disciplineId: Int!, $asTrainer: Boolean!) {
    addPersonDiscipline(personId:$personId,disciplineId:$disciplineId,asTrainer:$asTrainer){
        ok
    }
}
`;


const REMOVE_PERSON_FROM_DISCIPLIONE = gql`
mutation RemovePersonDiscipline($personId: Int!, $disciplineId: Int!) {
    removePersonDiscipline(personId:$personId,disciplineId:$disciplineId){
        ok
    }
}
`;



export default function DisciplineStudents() {

  const { disciplineId } = useParams();
  const [editMembers, setEditMembers] = useState(false)
  const [allGrades, setAllGrades] = useState([])
  const [filteredStudents, setFilteredStudents] = useState([])
  const [students, setStudents] = useState([])

  const { data, loading, error, refetch } = useQuery(
    GET_DISCIPLINE_STUDENTS,
    {
      variables: { disciplineId },
      onCompleted: data => {
        var _students = cloneDeep(data.discipline.students.edges.map(e => e.node));

        let grades = []
        for (let student of _students) {
          let grade = get_grade(student.grades, disciplineId);
          student.current_grade = grade;
          if (grade!==undefined && !grades.includes(grade)) {
            grades.push(grade)
          }
        }

        grades.sort(function(a,b){
          if(a.rank===b.rank)
            return 0;
          return a.rank > b.rank ? -1 : 1
        })

        setAllGrades(grades)
        _students.sort(sort_by_current_grade)
        setStudents(_students)
        setFilteredStudents(_students)
      },
    }
  );



  const [create] = useMutation(
    ADD_DISCIPLINE_TO_PERSON,
    {
      onCompleted({ create }) {
        refetch()
      }
    }
  );

  const [remove] = useMutation(
    REMOVE_PERSON_FROM_DISCIPLIONE,
    {
      onCompleted({ create }) {
        refetch()
      }
    }
  )


  function get_grade(grades, d_id) {
    for (let element of grades) {
      if (parseInt(element.discipline.id) == d_id) {
        return element
      }
    }
  }


  function add_member(member, discipline) {
    create({ variables: { personId: member.id, disciplineId: discipline.id, asTrainer: false } })
  }

  function remove_member(member, discipline) {
    remove({ variables: { personId: member.id, disciplineId: discipline.id } })
  }

  var members = useSelector((state) => state.currentUser.students)

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const discipline = data.discipline;
  if (discipline === null) {
    return (<Navigate to={`/landing`} />);
  }


  members = members.filter((m) => {
    return !students.some(s => s.id === m.id)
  })



  function truncate_grade(grade) {
    return grade ? grade.description.length > 20 ? `${grade.description.substring(0, 20)} ...` : grade.description : "";
  }

  function filter_grade(grade) {
    var s = []
    if (grade === null) {
      s = students;
    } else {
      for (var student of students) {

        if (student.current_grade && student.current_grade.id === grade.id) {
          s.push(student)
        }
      }
    }
    setFilteredStudents(s);
  }


  function Filter() {
    return (
      <div className='sudent_list_commands'>
        <button className="btn btn-primary" onClick={e => filter_grade(null)}>
          <Text tid="all" />
        </button>
        {allGrades.map(g => {
          return (
            <button key={`fbtn_${g.id}`} className="btn btn-primary" onClick={e => filter_grade(g)}>
              {g.description}
            </button>
          )
        })}
      </div>
    )
  }

  if (editMembers) {
    return (
      <Fragment>
        <div className="dm-container">
          <Link className='back' to={`/discipline/${discipline.id}`} >
            <Text tid="backto" /> {discipline.name}
          </Link>
          <div className='content-block'>
            <h2 className='content-block-header'>{discipline.name} <Text tid="Students" /> <button className="btn btn-primary" onClick={e => setEditMembers(false)}><Text tid="done" /></button></h2>
            <Filter />
            <div className="discipline-row">
              {filteredStudents.map(student => (
                <Fragment key={`edit_card_${student.id}`}>
                  <div className="trainerCard remove_list" onClick={() => remove_member(student, discipline)}>

                    <SecureImage fileId={student.avatar ? student.avatar.id : null} className="cardImage" alt={Profile} />

                    <div className="trainerCard-Title">{student.firstName} {student.lastName}</div>

                    <div className="trainerCard-card-grade">
                      {truncate_grade(student.current_grade)}
                    </div>
                    <div className='delete_hover_icon'>
                      <img className="remove_icon" src={RemoveIcon} alt="remove"/>
                    </div>
                  </div>

                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className='content-block'>
          <h2 className='content-block-header'><Text tid="AddMembers" /></h2>
          <Can I="editDiscipline" this={new DisciplineOBJ(discipline.id)}>
            <NewMemberForm callback={refetch} />
          </Can>
          <div className="discipline-row">
            {members.map(member => (
              <div key={member.id} className="trainerCard" onClick={() => add_member(member, discipline)} id={`card_${member.id}`}>
                <SecureImage fileId={member.avatar ? member.avatar.id : null} className="cardImage" alt={Profile} />
                <div className="trainerCard-Title">{member.firstName} {member.lastName}</div>
              </div>)
            )}
          </div>
        </div>
      </Fragment>
    )
  } else {

    return (
      <Fragment>
        <div className="dm-container">
          <Link className='back' to={`/discipline/${discipline.id}`} >
            <Text tid="backto" /> {discipline.name}
          </Link>
          <div className='content-block'>
            <h2 className='content-block-header'>{discipline.name} <Text tid="Students" /> <button className="btn btn-primary" onClick={e => setEditMembers(true)}><Text tid="edit_discipline_members" /></button></h2>
            <Filter />
            <div className="discipline-row">
              {filteredStudents.map(student => (
                <TrainerCard key={student.id} trainer={student} clicktarget={`/discipline/statistics/${discipline.id}/${student.id}`} />
              ))}
            </div>
          </div>
        </div>
      </Fragment >
    );
  }
}