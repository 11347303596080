import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SecureImage } from "./SecureImage";
import Profile from '../assets/icons/profile.svg';


export default function TrainerCard({ trainer, clicktarget }) {
    const navigate = useNavigate();
    var click_targe = `/person/${trainer.id}`;
    if (clicktarget) {
        click_targe = clicktarget
    }

    const current_grade_truncate = trainer.current_grade?trainer.current_grade.description.length > 20 ? `${trainer.current_grade.description.substring(0, 20)} ...` : trainer.current_grade.description:"";

    return (
        <div className="trainerCard" onClick={() => navigate(click_targe)} id={`card_${trainer.id}`}>

            <SecureImage fileId={trainer.avatar?trainer.avatar.id:null}  className="cardImage" alt={Profile}/>

            <div className="trainerCard-Title">{trainer.firstName} {trainer.lastName}</div>

            <div className="trainerCard-card-grade">
                {current_grade_truncate}
            </div>
        </div>)
}




