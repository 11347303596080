import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client'
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';


export default function ChangePassword({ id }) {


    const SAVEPASSWORD = gql`
    mutation ChangePassword($id:Int!,$password:String!){
        changePassword(id:$id,password:$password){
            ok,
            person{
                firstName,
                lastName
            }
        }
    }`;



    const [passwordMutation] = useMutation(
        SAVEPASSWORD
    );

    const [error, seterror] = useState("");
    const [pw1, setpw1] = useState("");
    const [pw2, setpw2] = useState("");

    const save = event => {
        if (pw1 === pw2 && pw1 !=="") {
            var newPW = pw1;
            document.getElementById('pw1').value = '';
            document.getElementById('pw2').value = '';
            setpw1("");
            setpw2("");
            passwordMutation({ variables: { id: id, password: newPW } });
            seterror("password succesfully changed");
        } else {
            seterror("passwords must be equal");
        }
    }


    const onChangePW1 = event => {
        const value = event.target.value;
        setpw1(value);
        if (value !== pw2) {
            seterror("passwords must be equal");
        } else if (value === pw2) {
            seterror("");
        }
    };

    const onChangePW2 = event => {
        const value = event.target.value;
        setpw2(value);
        if (value !== pw1) {
            seterror("passwords must be equal");
        } else if (value === pw1) {
            seterror("");
        }
    };
    const languageContext = useContext(LanguageContext);

    return (
        <div className="dm-container">
            <div className="ro mb-2">
                {error}
            </div>
            <div className="row mb-2">
                <div className="col-auto">
                    <input type="password" id="pw1" className="form-control" placeholder={languageContext.dictionary["newPassword"] || "New Password"} onChange={onChangePW1} />
                </div>
                <div className="col-auto">
                    <input type="password" id="pw2" className="form-control" placeholder={languageContext.dictionary["repeatPassword"] || "Repeat Password"} onChange={onChangePW2} />
                </div>
                <div className="col-auto">
                    <button onClick={save} className="btn btn-primary">{languageContext.dictionary["changePassword"] || "Change Password"}</button>
                </div>
            </div>

        </div>
    );

}
