import React from 'react';

export default function Loading() {

  return (
    <div className='loading_container'>
      <div className="loading"></div>
    </div>
  );
}

