import React, { Fragment } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Loading, EditableLabel } from "../components";
import { Text } from "../containers/Language";
import { Navigate, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import DisciplineCalendar from "../components/DisciplineCalendar";
import { useSelector } from "react-redux";

const SET_GRADE = gql`
  mutation SetGrade($gradeId: Int!, $userId: Int!) {
    setGrade(gradeId: $gradeId, userId: $userId) {
      ok
    }
  }
`;

export const GET_DISCIPLINE_DETAILS = gql`
  query StudentDisciplineStatistics(
    $userId: ID!
    $disciplineId: ID!
    $id: ID!
  ) {
    studentDisciplineStatistics(userId: $userId, disciplineId: $disciplineId) {
      currentGrade
      lastExam
      sessionsSinceLastExam
      topicsSinceLastExam {
        name
        count
      }
    }
    discipline(id: $disciplineId) {
      id
      name
      grades {
        rank
        description
        id
      }
    }
    person(id: $id) {
      firstName
      lastName
      exams {
        result {
          description
        }
        exam {
          dateTime
          discipline {
            id
          }
        }
      }
    }
  }
`;

export default function DisciplineUserStatistics({ disciplineId, userId }) {
  //depends on if the component is used inline (disciplineId and userID passed by props)
  //or as single page (disciplineId and userID passed as url prarams)
  let { discipline_Id, user_Id } = useParams();
  if (discipline_Id) {
    disciplineId = discipline_Id;
  }
  if (user_Id) {
    userId = user_Id;
  }

  //check if userId = current user id or disciplineId in current user teaches
  const current_user_id = useSelector((state) => state.currentUser.id);
  const teaches = useSelector((state) => state.currentUser.teaches);
  const isAdmin = useSelector((state) => state.currentUser.isAdmin);

  let date = new Date();
  let month = date.getUTCMonth() + 1; //months from 1-12
  let year = date.getUTCFullYear();
  let last_day = date.getUTCDate();

  date = new Date();
  date.setMonth(date.getMonth() - 6);
  month = date.getUTCMonth() + 1; //months from 1-12
  year = date.getUTCFullYear();
  last_day = date.getUTCDate();

  const { data, loading, error } = useQuery(GET_DISCIPLINE_DETAILS, {
    variables: {
      disciplineId: disciplineId,
      userId: userId,
      id: userId,
    },
  });

  const [setGrade] = useMutation(SET_GRADE);

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  if (
    current_user_id !== userId &&
    !teaches.some((e) => {
      return parseInt(e.id) === parseInt(disciplineId) ? true : false;
    }) &&
    !isAdmin
  ) {
    return <Navigate to={`/landing`} />;
  }

  const discipline = data.discipline;
  const stats = data.studentDisciplineStatistics;
  const grade = stats.currentGrade;
  //const last_exam = new Date(stats.lastExam).toLocaleDateString()
  const last_exam = stats.lastExam
    ? new Date(stats.lastExam).toLocaleDateString()
    : null;

  const sessions_since_last_exam = stats.sessionsSinceLastExam;
  const grade_label = grade ? grade : <Text tid="NoGrade" />;
  const all_grades = discipline.grades;
  const topics = stats.topicsSinceLastExam;

  var grade_values = [];
  for (var i = 0; i < all_grades.length; i++) {
    var g = [all_grades[i].id, all_grades[i].description];
    grade_values.push(g);
  }

  function editGrade(value) {
    var id;
    for (var i = 0; i < all_grades.length; i++) {
      if (all_grades[i].description === value) {
        id = all_grades[i].id;
      }
    }

    setGrade({
      variables: { userId: userId, gradeId: id },
      refetchQueries: [
        {
          query: GET_DISCIPLINE_DETAILS,
          variables: {
            disciplineId: disciplineId,
            userId: userId,
            id: userId,
          },
        },
      ],
    });
  }

  let discipline_exams = data.person.exams.filter(
    (exam) => exam.exam.discipline.id == disciplineId
  );
  return (
    <Fragment>
      <div className="dm-container">
        <Link className="back" to={`/discipline/students/${discipline.id}`}>
          <Text tid="backto" /> {discipline.name} <Text tid="students" />
        </Link>
        <div className="content-block">
          <h2 className="content-block-header">
            {data.person.firstName} {data.person.lastName}
          </h2>
          <h3>
            <Text tid="CurrentGrade" />
          </h3>
          <EditableLabel
            label={grade_label}
            canEdit={true}
            callback={editGrade}
            select_values={grade_values}
          />

          <h3>
            <Text tid="LastExam" />:
          </h3>
          {last_exam ? last_exam : <Text tid="NoExams" />}

          {sessions_since_last_exam ? (
            <>
              <h3>
                {last_exam ? (
                  <Text tid="SessionsSinceExam" />
                ) : (
                  <Text tid="SessionsSinceStart" />
                )}
                :
              </h3>
              {sessions_since_last_exam}
            </>
          ) : (
            ""
          )}

          {topics && topics.length > 0 ? (
            <>
              <h3>
                {last_exam ? (
                  <Text tid="TopicsSinceExam" />
                ) : (
                  <Text tid="TopicsSinceStart" />
                )}
                :
              </h3>
              <div className="topic_list">
                {topics.map((t) => {
                  return (
                    <div key={`t_${t.name}`} className="topic_element">
                      {t.name} ({t.count})
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
          {discipline_exams.length > 0 ? (
            <>
              <h3>
                <Text tid="allExams" />
              </h3>
              <div className="topic_list">
                {discipline_exams.map((exam) => {
                  return (
                    <div
                      key={`exam_${exam.exam.dateTime}`}
                      className="topic_element"
                    >
                      {exam.reult ? (
                        <span>
                          {exam.result.description} :{" "}
                          {new Date(exam.exam.dateTime).toLocaleDateString()}
                        </span>
                      ) : (
                        <Text tid="NoGrade" />
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
          <h3>
            <Text tid="ListOfSessions" />
          </h3>

          <DisciplineCalendar
            disciplines={[discipline.id]}
            forusers={[userId]}
          />
        </div>
      </div>
    </Fragment>
  );
}
