import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import store from '../store';

const GET_SESSIONS = gql`
query TrainingSessions($discipline_Id:[ID],$before:String, $after:String,$user_id:[ID]){
    trainingSessions(discipline_Id:$discipline_Id,before:$before,after:$after,user_Id:$user_id){
        edges{
            node{
                id,
                startDateTime,
                endDateTime,
                discipline{
                    id,
                    name
                },
                description,
                topics{
                    id,
                    name,
                    category{
                        name
                    }
                }
            }
        }
    },
    exams(discipline_Id:$discipline_Id,before:$before,after:$after,user_Id:$user_id){
        edges{
            node{
                id,
                dateTime,
                discipline{
                    id,
                    name
                }
            }
        }
    }
}
`;

export default function DisciplineCalendar({ disciplines, forusers }) {
    let init_d = new Date()
    let month = init_d.getUTCMonth() + 1; //months from 1-12
    let year = init_d.getUTCFullYear();
    let last_day = new Date(year, month, 0).getDate()


    const [after, setAfter] = useState("01/" + month + "/" + year + " 00:00:00")
    const [before, setBefore] = useState(last_day + "/" + month + "/" + year + " 23:59:59")
    const [current_date, setCurrenDate] = useState(new Date())
    const [view, setView] = useState("month")
    const navigate = useNavigate();

    function setWeekDate(range) {
        let date = range[0]
        let month = date.getUTCMonth() + 1; //months from 1-12
        var year = date.getUTCFullYear();
        var day = date.getUTCDate();
        var day1 = new Date(year, month, day).getDate()


        let date2 = range[range.length - 1];
        month = date2.getUTCMonth() + 1; //months from 1-12
        year = date2.getUTCFullYear();
        day = date2.getUTCDate();
        var day2 = new Date(year, month, day).getDate()
        //BUG day off by -1
        setAfter(day1 + "/" + month + "/" + year + " 00:00:00")
        setBefore(day2 + "/" + month + "/" + year + " 23:59:59")
    }

    function handleNaviagtion(date, view, action) {
        setCurrenDate(date)
    }

    function setMonthDate(range) {
        let date = range.start
        let month = date.getUTCMonth() + 1; //months from 1-12
        let year = date.getUTCFullYear();


        let date2 = range.end
        let month2 = date2.getUTCMonth() + 1; //months from 1-12
        let year2 = date2.getUTCFullYear();
        let last_day = date2.getUTCDate();

        setAfter("01/" + month + "/" + year + " 00:00:00")
        setBefore(last_day + "/" + month2 + "/" + year2 + " 23:59:59")
    }



    function eventSelection(event) {
        if(event.type==="session"){
            navigate(`/session/${event.id}`)
        }
        if(event.type==="exam"){
            navigate(`/exam/${event.id}`)
        }
    }

    function rangeChange(range, view) {
        if (range.start) {
            setMonthDate(range)
        } else {
            setWeekDate(range)
        }
        if (view) {
            setView(view)
        }

    }

    function eventStyleGetter(event, start, end, isSelected){
  
        var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }


    const { data, loading, error, refetch } = useQuery(
        GET_SESSIONS,
        {
            skip: disciplines.length === 0,
            variables: {
                "discipline_Id": disciplines,
                "user_id": forusers,
                "before": before,
                "after": after
            }
        },
    );




    let sessions_dirty = useSelector((state) => state.sessions.dirty)
    store.subscribe(() => {
        let previous = sessions_dirty
        sessions_dirty = store.getState().sessions.dirty
        if (previous !== sessions_dirty) {
            refetch()
        }
    });



    //start week on monday
    moment.locale(moment.locale, {
        week: {
            dow: 1,
            
        },
    })

    let formats = {
        timeGutterFormat: 'HH:mm',
      }

    const localizer = momentLocalizer(moment)


    if (loading) return <Loading />;
    if (error) return `Error! ${error.message}`;

    let events = []


    if(data && data.trainingSessions){
        data.trainingSessions.edges.forEach(e => {
            events.push(
                {
                    "id": e.node.id,
                    "title": e.node.discipline.name,
                    "start": new Date(e.node.startDateTime),
                    "end":new Date(e.node.endDateTime),
                    'hexColor':"1d607a",
                    "type":"session"
                }
            )
        })
        data.exams.edges.forEach(e=>{
            events.push({
                "id":e.node.id,
                "title": e.node.discipline.name,
                "start": new Date(e.node.dateTime),
                "end": new Date(e.node.dateTime),
                'hexColor':"f44336",
                "type":"exam"
            })
        })
    
    }
    

    return (

        <Fragment>
            <Calendar
                localizer={localizer}
                formats={formats}
                events={events}
                startAccessor="start"
                endAccessor="end"
                showMultiDayTimes
                step={60}
                timeslots={1}
                date={current_date}
                style={{ height: 500 }}
                defaultView={view}
                onNavigate={handleNaviagtion}
                onSelectEvent={eventSelection}
                onRangeChange={rangeChange}
                eventPropGetter={(eventStyleGetter)}
            />
        </Fragment>
    )
}