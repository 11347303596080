export function sort_by_current_grade(a, b) {
    if (a.current_grade === undefined) {
        return 1
    }

    if (b.current_grade === undefined) {
        return -1
    }

    if (a.current_grade.rank === b.current_grade.rank)
        return 0

    return a.current_grade.rank > b.current_grade.rank ? -1 : 1
}
