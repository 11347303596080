import React, { Fragment } from 'react';
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { LoginForm, Loading } from '../components';
import Header from '../components/header';
import { Navigate } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

export const LOGIN_USER = gql`
mutation TokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token,
    payload,
  }
}
`;




export default function Login() {

  const navigate = useNavigate();

  const [login, { loading, error }] = useMutation(
    LOGIN_USER,
    {
      onCompleted: (login) => {
        localStorage.setItem('token', login.tokenAuth.token);
        navigate("/landing");
      },
      onError: (error) => {

      }
    }
  );

  if (loading) return (<Loading />);

  return (<Fragment>
    <div className='splash-container'>
      <Header />
      <div className="login_container">
        <div className="login_form login_error">
          <div className="row mb-3">
            {error ? error.message : ""}
          </div>
        </div>
        <LoginForm login={login} />

      </div>
    </div>
  </Fragment>
  )
}


