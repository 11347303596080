import { configureStore } from '@reduxjs/toolkit'
import currentUserReducer from './storeSlices/currentUserSlice'
import featureReducer from './storeSlices/featureSlice'
import sessionsSlice from './storeSlices/sessionsSlice'
import secureFileSlice from './storeSlices/secureFileSlice'

export default configureStore({
  reducer: {
      currentUser: currentUserReducer,
      features:featureReducer,
      sessions:sessionsSlice,
      files:secureFileSlice
  },
})