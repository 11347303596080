import React, { useState, createContext, useContext } from 'react';
import parse from 'html-react-parser';
import { languageOptions, dictionaryList } from '../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'de',
  dictionary: dictionaryList.de
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  let defaultLanguage =  navigator.language || navigator.userLanguage;
  
  if(defaultLanguage.startsWith("en")){
    defaultLanguage='en'
  }else if(defaultLanguage.startsWith("de")){
    defaultLanguage='de'
  }else{
    defaultLanguage='en'
  }

  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'en'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);
  let r =languageContext.dictionary[tid] || tid;
  return parse(r);
};