import React, { Fragment, useState } from 'react';
import { useParams,Link } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../../components';
import { Text } from '../../containers/Language';
import { Navigate } from "react-router-dom";
import { enabled } from '../../components/features';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSortUp,
  faSortDown,
  faSort,
  faUserSlash,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NewMemberForm from '../../components/new-member-form';


import { GlobalFilter, DefaultFilterForColumn } from "../../components/members-filter";

export const MEMBERS_DATA = gql`
fragment member on PersonType{
       __typename,
        firstName,
        lastName,
        id,
        isTrainer,
        avatar{
          file,
          id
        },
        email
}
`;

export const GET_DOJO_STUDENTS = gql`
query Dojo($dojoId: ID!){
  dojo(id: $dojoId){
        id,
        name,
        members{
          edges{
            node{
              ...member
            }
          }
          
        },
        disciplines{
          id,
          name
        }
      }
}
${MEMBERS_DATA}
`;


const DELETEUSER = gql`
mutation DeletePerson($id:Int!){
  deletePerson(id:$id){
   ok
  }
}`;

const RESET = gql`
mutation ResetPassword($email:String!){
  resetPassword(email:$email){
   ok
  }
}`;


function Table({ columns, data, dojoId }) {

  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState({})
  const [showResetPWDialog, setShowResetPWDialog] = useState(false)
  const [userToReset, setUserToReset] = useState({})

  const navigate = useNavigate();


  const [personMutation] = useMutation(
    DELETEUSER,
    {
      refetchQueries: [
        {
          query: GET_DOJO_STUDENTS,
          variables: { dojoId, offset: 0, limit: 10 },
        },
      ]
    }
  );

  const [resetMutation] = useMutation(
    RESET
  )

  function delete_user() {
    setShowDeleteUserDialog(false)

    personMutation({ variables: { id: userToDelete.id } });



  }

  function DeleteDialog() {

    return (
      <div className='delete_user_dialog'>
        <div className='delete_user_dialog_inner'>
          <div>Delete {userToDelete.firstName} {userToDelete.lastName} ?</div>
          <div className='delete_user_dialog_inner_warning'><Text tid="can_not_be_undone" /></div>
          <div>
            <button className="btn btn-primary m-2" onClick={() => setShowDeleteUserDialog(false)}><Text tid="cancel" /></button>
            <button className="btn btn-primary m-2" onClick={() => delete_user()}><Text tid="yes" /></button>
          </div>
        </div>
      </div>
    )
  }


  function reset_pw() {
    setShowResetPWDialog(false)
    resetMutation({ variables: { email: userToReset.email } })

  }

  function ResetDialog() {

    return (
      <div className='delete_user_dialog'>
        <div className='delete_user_dialog_inner'>
          <div><Text tid="Reset_pw" /> {userToDelete.firstName} {userToDelete.lastName} ?</div>

          <div>
            <button className="btn btn-primary m-2" onClick={() => setShowResetPWDialog(false)}><Text tid="cancel" /></button>
            <button className="btn btn-primary m-2" onClick={() => reset_pw()}><Text tid="yes" /></button>
          </div>
        </div>
      </div>
    )
  }



  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    //headerGroups,
    headers,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    preGlobalFilteredRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{ id: 'firstName' }], pageSize: 20 },
      disableSortRemove: true,
      defaultColumn: { Filter: DefaultFilterForColumn },
    },
    useFilters,
    useGlobalFilter,

    useSortBy,
    usePagination,

  )


  function show_delete_dialog(user) {
    setShowDeleteUserDialog(true);
    setUserToDelete(user);
  }

  function show_reset_pw_dialog(user) {
    setShowResetPWDialog(true)
    setUserToReset(user)
  }

  // Render the UI for your table
  return (
    <>
      {showDeleteUserDialog ? <DeleteDialog /> : ""}
      {showResetPWDialog ? <ResetDialog /> : ""}
      <table {...getTableProps()}>
        <thead>
          <tr>
            <th

            >
              {/* Rendering Global Filter */}
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          <tr>
            {headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span className='table-header-icon'>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <FontAwesomeIcon icon={faSortDown} />
                      : <FontAwesomeIcon icon={faSortUp} />
                    : <FontAwesomeIcon icon={faSort} />}
                </span>
              </th>
            ))}
            <td><Text tid="edit" /></td>
            <td><Text tid="delete" /></td>
            <td><Text tid="reset_pw" /></td>
          </tr>


        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td><button className='table_button' onClick={() => { navigate(`/person/${row.original.id}/edit`) }}><FontAwesomeIcon icon={faEdit} className="mr-2 table_edit" /></button></td>
                <td><button className='table_button' onClick={() => show_delete_dialog(row.original)}><FontAwesomeIcon icon={faUserSlash} className="mr-2 table_edit" /></button></td>
                <td>{row.original.isTrainer ? <button className='table_button' onClick={() => show_reset_pw_dialog(row.original)}><FontAwesomeIcon icon={faKey} className="mr-2 table_edit" /></button> : ""}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="table-pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}





export default function Members() {
  const { dojoId } = useParams();

  function canAddStudents(students) {
    return enabled("unlimited_students") || students.length < 50
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Trainer',
        accessor: d => d.isTrainer ? "trainer" : "",
      },
    ],
    []
  )

  const { data, loading, error, refetch } = useQuery(
    GET_DOJO_STUDENTS,
    {
      variables: { dojoId, offset: 0, limit: 10 }
      , fetchPolicy: "cache-and-network"
    },
  );


  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const dojo = data.dojo;
  if (dojo === null) return <Navigate to='/' />
  const students = dojo.members.edges.map(e => e.node);;

  return (
    <Fragment>
      <div className="dm-container">
        <Link className='back' to={`/dojo/${dojoId}`} >
          <Text tid="backto" /> <Text tid="ManageDojo" />
        </Link>
        <div className='content-block'>
          <h2 className='content-block-header'><Text tid="Members" /></h2>

          <div className="row mb-3">


            <Table columns={columns} data={students} dojoId={dojoId} />

          </div>

          {canAddStudents(students) ? <NewMemberForm callback={refetch} /> : ""}
        </div>
      </div>
    </Fragment>
  );
}
