import React, {useState} from 'react';
import { useQuery } from '@apollo/client'
import {useSelector, useDispatch} from 'react-redux';
import  {setID,setIsTrainer,setIsAdmin,setIsSuperuser,setDojoID,setDojoName,clearUser,setTeaches, setStudies,setTeachers,setStudents, setDisciplines} from '../storeSlices/currentUserSlice';
import Loading from './loading';
import gql from 'graphql-tag';


const DISCIPLINE_DATA = gql`
fragment discipline_on_landing on DisciplineType{
    __typename,
    name,
    id,
    icon{
        id
    },
    dojo{
      id,
    },
  }
`;

const USER_DATA = gql`
fragment landing_user on PersonType{
       __typename,
        firstName,
        lastName,
        isTrainer,
        isDojoleader,
        isSuperuser,
        id,
        avatar{
            file
        }
}
${DISCIPLINE_DATA}
`;

const GET_CURRENT_USER_DATA = gql`
query CurrentUser{
  currentUser{
        ...landing_user,
        teaches{
            ...discipline_on_landing
        },
        studies{
            ...discipline_on_landing
        },
        dojo{
            name,
            id,
            disciplines{
                name,
                id,
                icon{
                    id
                },
                grades{
                    id,
                    rank,
                    description
                }
            },
            members{
                edges{
                    node{
                        id,
                        isTrainer,
                        isDojoleader,
                        firstName,
                        lastName,
                        avatar{
                          id
                        },
                    }
                }
            }
        }
    }
}
${USER_DATA}
${DISCIPLINE_DATA}
`;


export default function LocalStorageProvider(props) {

    let token = localStorage.getItem('token')
    const currentUserId = useSelector((state)=>state.currentUser.id)
    const dispatch = useDispatch()




    const { data, loading, error } = useQuery(
        GET_CURRENT_USER_DATA,
        {
            skip:(currentUserId!==null && token !== null)|| token===null,
            onCompleted: (data) => {
                let currentUser = data.currentUser;
                dispatch(setID(currentUser.id))
                dispatch(setIsTrainer(currentUser.isTrainer || currentUser.isDojoleader || currentUser.isSuperuser));
                dispatch(setIsAdmin(currentUser.isDojoleader || currentUser.isSuperuser));
                dispatch(setIsSuperuser( currentUser.isSuperuser))
                dispatch(setDojoID(currentUser.dojo.id))
                dispatch(setDojoName(currentUser.dojo.name))
                let teaches = [];
                currentUser.teaches.forEach(element => {
                    teaches.push(element)
                });
                dispatch(setTeaches(teaches))
                let studies = [];
                currentUser.studies.forEach(element => {
                    studies.push(element)
                });
                dispatch(setStudies(studies))

                let teachers = []
                let students = []
                currentUser.dojo.members.edges.forEach(m=>{
                    if (m.node.isTrainer){
                        teachers.push(m.node)
                        students.push(m.node)
                    }else{
                        students.push(m.node)
                    }
                })
                dispatch(setTeachers(teachers))
                dispatch(setStudents(students))
                dispatch(setDisciplines(currentUser.dojo.disciplines))

            }
        }

    );

    if (token === null && currentUserId!==null) {
       dispatch(clearUser())
    }

    //if (currentUserId==null){
    //    return <Loading />
   // }
   if(error){
       return(
           error.message
       )
   }

    return (
        props.children
    );
}