import React, {  Suspense } from 'react';
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { GET_PERSON_DETAILS } from '../pages/person'
import { Text } from '../containers/Language';
import { SecureImage } from './SecureImage';
import DisciplineIcon from '../assets/icons/discipline.svg';

export const ADD_DISCIPLINE_TO_PERSON = gql`
mutation AddPersonDiscipline($personId: Int!, $disciplineId: Int!, $asTrainer: Boolean!) {
    addPersonDiscipline(personId:$personId,disciplineId:$disciplineId,asTrainer:$asTrainer){
        ok
    }
}
`;



export default function AddDisciplineToPersonForm({ person, availableDisciplines, selected, asTrainer }) {
    const personId = person.id;

    function add_single_discipline(item) {

        create({ variables: { personId: personId, disciplineId: item.id, asTrainer: asTrainer } })
    }

    const [create, { loading, error }] = useMutation(
        ADD_DISCIPLINE_TO_PERSON,
        {
            variables: { personId },
            refetchQueries: [
                {
                    query: GET_PERSON_DETAILS,
                    variables: { personId },
                },
            ]
        }
    );

    if (loading) return <p>Loading</p>;
    if (error) return <p>An error occurred: {error.message}</p>;

    return (
        <>
            <h2><Text tid="AddDiscipline" />:</h2>

            {availableDisciplines.map(discipline => (
                <div className="disciplineCard card m-1" onClick={(e) => add_single_discipline(discipline)} id={`card_${discipline.id}`} key={`card_${discipline.id}`}>
                    <div className="card-body discipline-card-body">
                        <Suspense>
                            <SecureImage fileId={discipline.icon? discipline.icon.id: null} alt={DisciplineIcon}/>                        </Suspense>
                        <div className="descipline-card-title">
                            {discipline.name}
                        </div>
                    </div>
                </div>

            ))}
        </>)
}



