import React, { Fragment, useState, Suspense } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading, EditableLabel } from '../components';
import { DISCIPLINE_DATA } from './dojo';
import DisciplineList from '../containers/disciplines-list';

import AddDisciplineToPersonForm from '../components/add-discipline-to-person-form';
import LanguageSelector from '../components/LanguageSelector';
import { useParams } from "react-router-dom";
import ChangePassword from '../components/change-password';

import { Text } from '../containers/Language';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import { Can } from '../security/can';
import PersonOBJ from '../DataClasses/PersonOBJ';
import { Navigate, Link } from "react-router-dom";
import { useAbility } from '@casl/react';
import { AbilityContext } from '../security/can';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import EditableImage from '../components/editable-image';

export const GET_PERSON_DETAILS = gql`
    query PersonDetails($personId: ID!) {
        person(id: $personId) {
            firstName,
            lastName,
            isTrainer,
            id,
            username,
            email,
            avatar{
              file,
              id
            },
            dojo{
              id,
              name,
              disciplines{
                ...discipline_on_dojo
              }
            },
            teaches{
              ...discipline_on_dojo
            }
            studies{
              ...discipline_on_dojo
            },
            grades{
                id,
                description,
                discipline{
                    id
                }
            }
        }
    }
 ${DISCIPLINE_DATA}
`;


const SAVENAME = gql`
mutation UpdatePerson($id:Int!,$input:PersonInput!){
  updatePerson(id:$id,input:$input){
   ok,
   person{
     firstName,
     lastName
   }
  }
}`;



export default function Person() {
  const { personId } = useParams();
  const [newMail, _setNewMail] = useState("")
  

  const { data, loading, error } = useQuery(
    GET_PERSON_DETAILS,
    { variables: { personId } },
  );

  const [personMutation] = useMutation(
    SAVENAME,
    {
      refetchQueries: [
        {
          query: GET_PERSON_DETAILS,
          variables: { personId },
        },
      ]
    }
  );

  const setNewEmail = event => {
    const value = event.target.value;
    _setNewMail(value);
  }

  const toTrainer = event => {
    var nMail = newMail;
    if (person.email !== "" && newMail === "") {
      nMail = person.email;
    }
    if (nMail !== "") {
      var personInput = {
        dojoId: person.dojo.id,
        firstname: person.firstName,
        lastname: person.lastName,
        username: person.username,
        email: nMail,
        avatarID: person.avatar ? person.avatar.id : 0,
        isTrainer: true
      }
      personMutation({ variables: { id: person.id, input: personInput } });
    }
  }

  const toStudent = event => {
    var nMail = newMail;
    if (person.email !== "" && newMail === "") {
      nMail = person.email;
    }
    if (nMail !== "") {
      var personInput = {
        dojoId: person.dojo.id,
        firstname: person.firstName,
        lastname: person.lastName,
        username: person.username,
        email: nMail,
        avatarID: person.avatar ? person.avatar.id : 0,
        isTrainer: false
      }
      personMutation({ variables: { id: person.id, input: personInput } });
    }
  }





  function saveName(newValues) {
    
    const firstName = newValues[0];
    const lastName = newValues[1];
    var personInput = {
      dojoId: person.dojo.id,
      firstname: firstName,
      lastname: lastName,
      username: person.username,
      email: person.email,
      avatarID: person.avatar ? person.avatar.id : 0,
      isTrainer: person.isTrainer
    }


    personMutation({ variables: { id: person.id, input: personInput } });
  }




  function get_grade_name(grades, d_id) {
    for (let element of grades) {
      if (parseInt(element.discipline.id) == d_id) {
        return element.description;
      }
    }
  }


  function saveAvatar(avatarID) {

    var personInput = {
      dojoId: person.dojo.id,
      firstname: person.firstName,
      lastname: person.lastName,
      username: person.username,
      email: person.email,
      avatarID: avatarID,
      isTrainer: person.isTrainer
    }


    personMutation({ variables: { id: person.id, input: personInput } });
  }
  const languageContext = useContext(LanguageContext);
  const ability = useAbility(AbilityContext);

  const id = useSelector((state) => state.currentUser.id)
  const isAdmin = useSelector((state) => state.currentUser.isAdmin)
  const current_user_teaches = useSelector((state) => state.currentUser.teaches)
  const current_user_is_trainer = useSelector((state) => state.currentUser.isTrainer)

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;


  const person = data.person;
  if (person === null) return <Navigate to='/landing' />



  const clicktarget = 'discipline/statistics';
  const foruser = personId;
  const grades = person.grades;
  const studies = cloneDeep(person.studies);
  const teaches = cloneDeep(person.teaches);
  teaches.forEach(element => {
    element.current_grade = get_grade_name(grades, element.id);
  });

  studies.forEach(element => {
    element.current_grade = get_grade_name(grades, element.id);
  });
  var dojoDisciplines = current_user_teaches;

  if (isAdmin) {
    dojoDisciplines = person.dojo.disciplines
  }


  function is_not_studies(ele) {
    if (studies) {
      return !studies.some(d => ele.id === d.id)
    }
    return true

  }

  function is_not_teaches(ele) {
    if (teaches) {
      return !teaches.some(d => ele.id === d.id)
    }
    return true
  }


  const non_stud_disc = dojoDisciplines.filter(is_not_studies)
  const availableTrainingDisciplies = studies ? non_stud_disc : dojoDisciplines;

  const non_train_disc = dojoDisciplines.filter(is_not_teaches)
  const availableTeachingDisciplies = teaches ? non_train_disc : dojoDisciplines;
  const personObject = new PersonOBJ(person.id, person.dojo.id);

  return (
    <Fragment>
      <div className="dm-container">
        {isAdmin === true || person.isTrainer ?
          <Link className='back' to={`/members/${person.dojo.id}`} >
            <Text tid="backto" /><Text tid="ManageMembers" />
          </Link>
          : <></>}

        <div className='content-block'>

          <div className="col-5">
            <h2 className='content-block-header'>
              <EditableLabel label={[person.firstName , person.lastName]}
                             canEdit={ability.can('edit', personObject)}
                             callback={saveName}
                              />
            </h2>
          </div>

       <EditableImage fileId={person.avatar?person.avatar.id:null} afterSave={saveAvatar} personObject={personObject} />


        </div>

        <div className='content-block'>

          <h2 className='content-block-header'><Text tid="Trains" />:</h2>

          <div className="discipline-row mb-2">
            {studies ? <DisciplineList disciplines={studies} dojoId={person.dojo.id} clicktarget={clicktarget} foruser={foruser} /> : ""}
          </div>
          {availableTrainingDisciplies.length > 0 && current_user_is_trainer ?

            <div className="row">
              <AddDisciplineToPersonForm person={person} selected={studies} availableDisciplines={availableTrainingDisciplies} asTrainer={false} />
            </div>

            : ""}
        </div>



        {person.isTrainer ?
          (
            <div className='content-block'>

              <h2 className='content-block-header'><Text tid="Teaches" />:</h2>

              <div className="discipline-row mb-2">
                {person.isTrainer && teaches ?
                  <DisciplineList disciplines={teaches} dojoId={person.dojo.id} clicktarget={clicktarget} foruser={foruser} /> : ""}
              </div>
              {isAdmin && availableTeachingDisciplies.length > 0 ?
                <Can I="edit" on={personObject}>
                  <div className="row">
                    {person.isTrainer ? <AddDisciplineToPersonForm person={person} availableDisciplines={availableTeachingDisciplies} asTrainer={true} /> : ""}
                  </div>
                </Can>
                : ""}
            </div>

          ) : ("")
        }



        {person.id == id ?
          <div className='content-block'>

            <h2 className='content-block-header'> <Text tid="langSelect" /></h2>
            <LanguageSelector />

          </div>
          : ""
        }



        {person.id == id ?
          <div className='content-block'>

            <h2 className='content-block-header'> <Text tid="Change password" /></h2>
            <ChangePassword id={person.id} />

          </div>
          : ""
        }

        {isAdmin === true && !person.isTrainer ?
          <div className='content-block'>
            <h2 className='content-block-header'><Text tid="convertToTrainer" /></h2>

            <div className="input-group">
              <input className="form-control  mx-1" type="text" defaultValue={person.email} placeholder={languageContext.dictionary["Email"] || "Email"} onChange={setNewEmail} />
              <button className="btn btn-primary" onClick={toTrainer}><Text tid="Save" /></button>
            </div>
          </div>
          : ""
        }



        {isAdmin === true && person.isTrainer ?
          <div className='content-block'>
            <h2 className='content-block-header'><Text tid="convertToStudent" />:</h2>
            <button className="btn btn-primary" onClick={toStudent}><Text tid="convertToStudent" /></button>
          </div>
          : ""
        }

        
      </div>

    </Fragment >
  );
}


