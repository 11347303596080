import React, { Fragment } from 'react';
import Header from '../components/header';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../containers/Language';
import config from 'react-global-configuration';
import Cookies from 'js-cookie'

export default function Contact() {


    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])


    const [user_captcha_value, set_user_captcha_value] = useState("")
    const [msg, set_msg] = useState("")
    const [mail, set_mail] = useState("")
    const [captcha_error, set_captcha_error] = useState("")
    const [posted, set_posted] = useState("")

    function submit() {

        if (validateCaptcha(user_captcha_value) == true) {
            set_captcha_error('');
            loadCaptchaEnginge(6);
            set_user_captcha_value("")

            fetch(config.get('baseurl') + "contact/", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": Cookies.get("csrftoken"),
                },
                body: JSON.stringify({
                    msg: msg,
                    email: mail,
                    captcha: true,
                })
            }).then(function (response) {
                set_msg("");
                set_mail("");
                set_posted("ContactRequestSent")
            })
        }
        else {
            set_captcha_error('CaptchaDoesNotMatch');
            loadCaptchaEnginge(6);
            set_user_captcha_value("")
        }
    }

    return <Fragment>
        <div className='splash-container'>
            <Header />
            <div className="dm-container">
                <div className="dm-container">
                    <div className='content-block'>
                        <h1><Text tid="Contact" /></h1>
                        <div className="request_success" ><Text tid={posted} /></div>
                        <div className="row mb-3">
                            <label htmlFor="mail"><Text tid="Email" /></label>
                            <input id="mail" className="form-control mb-2" type="email" onChange={e => set_mail(e.target.value)} />
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="msg"><Text tid="support_message" /></label>
                            <textarea id="msg" className="form-control mb-2" onChange={e => set_msg(e.target.value)} rows="20" ></textarea>
                        </div>
                        <div className='contact_form'>
                            <div className="row mb-3">
                                <span className='contact_error'><Text tid={captcha_error} /></span>

                                <div className='captcha_row'>
                                    <LoadCanvasTemplateNoReload />
                                    <input className="form-control mb-2" type="text" onChange={e => set_user_captcha_value(e.target.value)} />
                                </div>
                                <button className="btn btn-primary mb-2" onClick={e => submit()}><Text tid="send" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}

