import React, { Fragment, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams } from "react-router-dom";
import { Text } from '../containers/Language';
import { Navigate, Link } from "react-router-dom";
import { Can } from '../security/can';
import DisciplineOBJ from '../DataClasses/Discipline';


export const CREATE_EXAM = gql`
mutation CreateExam($input: ExamInput!) {
createExam(input:$input){
    exam{
      id,
      dateTime,
      discipline{
        id,
        name
      }
    }
  }
}
`;


export const EXAM_DATA = gql`
fragment exam on ExamTypeConnection{
  edges{
    node{
      __typename,
      id,
      dateTime,
    }
  }
}
`;


export const GET_DISCIPLINE_EXAMS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        dojo{
          id,
          name
        },
        exams{
          ...exam
        }
      }
    }
    ${EXAM_DATA}
`;


export default function DisciplineExams() {

  const { disciplineId, refresh } = useParams();

  const [newExam, setNewExam] = React.useState(false);
  const [newExamId, setNewExamId] = React.useState(0);

  const { data, loading, error, refetch } = useQuery(
    GET_DISCIPLINE_EXAMS,
    { variables: { disciplineId } },
  );

  useEffect(() => { refetch() }, [refresh])

  const [createExam] = useMutation(
    CREATE_EXAM,
    {
      onCompleted: (result) => {
        updateExamState(result.createExam.exam.id);
      }
    }
  );


  function createNewExam() {
    const input = {
      disciplineId: discipline.id
    }
    createExam({ variables: { input: input } });
  }

  function updateExamState(examId) {
    setNewExamId(examId);
    setNewExam(true);
  }


  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const discipline = data.discipline;
  if (discipline === null) {
    return (<Navigate to={`/landing`} />);
  }

  let exams = discipline.exams.edges.map(edges => edges.node);
  exams.sort((a, b) => (a.dateTime > b.dateTime) ? -1 : 1)
  if (newExam) {
    return (<Navigate to={`/exam/${newExamId}/edit`} />);
  }

  return (
    <Fragment>
      <div className="dm-container">
        <Link className='back' to={`/discipline/${discipline.id}`} >
          <Text tid="backto" /> {discipline.name}
        </Link>
        <div className='content-block'>
          <h2 className='content-block-header'>
            {discipline.name} <Text tid="Exams" /></h2>


          <Can I="startexam" on={new DisciplineOBJ(discipline.id)}>
            <button type="button" className="btn btn-primary mb-5" onClick={createNewExam}><Text tid="StartNewExam" /></button>
          </Can>

          {exams.map(exam => (
            <Link to={`/exam/${exam.id}`} key={`exam_${exam.id}`}>
              <div className="session-row">
                <div>{new Date(exam.dateTime).toLocaleString()}</div>

              </div>
            </Link>
          ))}
        </div>

      </div>
    </Fragment>
  );

}
