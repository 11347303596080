import React from 'react';



export default function DojoCard({dojo}) {

    return (
        <a href={`/dojo/${dojo.id}`}>
        <div className="mb-1">
            <h2> {dojo.name}</h2>
        </div>
      </a>
       
    );
}

