import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import DisciplineList from '../containers/disciplines-list';
import { useParams } from "react-router-dom";
import { Text } from '../containers/Language';
import { Navigate } from "react-router-dom";
import { enabled } from '../components/features'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import NewDisciplineForm from '../components/new-discipline-form';
import { Can } from '../security/can'

export const DISCIPLINE_DATA = gql`
fragment discipline_on_dojo on DisciplineType{
    __typename,
    name,
    id,
    icon{
      id
    },
    dojo{
      id,
      name
    },
  }
`;


export const GET_DOJO_DETAILS = gql`
query Dojo($dojoId: ID!){
  dojo(id: $dojoId){
        name,
        id,
        logoUrl,
        location{
          city,
          country
        }
        disciplines{
          ...discipline_on_dojo
        },
 
      }
    }
${DISCIPLINE_DATA}
`;


export default function Dojo() {

  const { dojoId } = useParams();
  const isAdmin = useSelector((state) => state.currentUser.isAdmin)

  const { data, loading, error } = useQuery(
    GET_DOJO_DETAILS,
    { variables: { dojoId } },
  );


  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const dojo = data.dojo;
  if (dojo === null) {
    return (<Navigate to={`/landing`} />);
  }

  const disciplines = dojo.disciplines;




  return (
    <Fragment>
      <div className="dm-container">
        <div className='content-block'>
          <h2 className='content-block-header'><Text tid="Disciplines" /></h2>
          {disciplines ? <DisciplineList disciplines={disciplines} editable={true} dojoId={dojo.id} clicktarget='discipline' /> : ""}

        </div>

        <div className='content-block'>
          <h2 className='content-block-header'>
            <Text tid="ManageDojo" />
          </h2>
          <div className='action-block'>
            <Link className="btn btn-primary" to={`/members/${dojo.id}`} ><Text tid="ManageMembers" /></Link>
            <Can I="add" a="discipline">
              <NewDisciplineForm dojoId={dojoId} />
            </Can>

          </div>
          <div className='action-block'>
            {(isAdmin) ? <Link className="btn btn-primary" to={`/memberships/payments/`}><Text tid="manageMemberPayments" /></Link> : ''}
            {(isAdmin) ? <Link className="btn btn-primary" to={`/memberships/`}><Text tid="manageMemberships" /></Link> : ''}
            {(isAdmin) ? <Link className="btn btn-primary" to={`/memberships/types/`}><Text tid="manageMembershipTypes" /></Link> : ''}


          </div>

          <div className='action-block'>
            {(enabled("subscriptions") && isAdmin) ? <Link className="btn btn-primary" to={`/subscriptions/${dojo.id}`}><Text tid="manageSubscriptions" /></Link> : ''}
          </div>
        </div>

      </div>
    </Fragment>
  );

}