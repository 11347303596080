import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dojos from './dojos';
import Dojo from './dojo'
import { PageContainer } from '../components';
import Person from './person';
import Members from './memberships/members';
import Discipline from './discipline';
import DisciplineStudents from './disciplinestudents';
import DisciplineTrainers from './disciplinetrainers';
import DisciplineUserStatistics from './disciplineuserstatistics';
import DisciplineTopics from './disciplinetopics';
import DisciplineStatistics from './disciplinestatistics';
import DisciplineExams from './disciplineexams'
import DisciplineGrades from './disciplinegrades'
import Private from '../AuthenticatedRoute';
import Session from './session';
import Landing from './landing';
import Register from './register';
import Exam from './exam';
import Login from './login';
import Splash from './splash';
import Subscriptions from './subscriptions';
import Dataprotection from './dataprotection';
import Impressum from './impressum';
import MembershipTypes from './memberships/membershiptypes';
import Memberships from './memberships/memberships';
import Payments from './memberships/payments'
import Contact from './contact';
import ContactPrivate from './contact_private';

export default function Pages() {

  return (
    <Router >
      <Fragment>
        <PageContainer>
          <Routes>
            <Route exact path="/register/:product" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/dataprotection" element={<Dataprotection />} />
            <Route exact path="/impressum" element={<Impressum />} />
            <Route exact path="/" element={<Splash />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/support" element={<Private Component={ContactPrivate} />} />
            <Route exact path="/landing" element={<Private Component={Landing} />} />
            <Route exact path="/admin" element={<Private Component={Dojos} />} />
            <Route exact path="/dojo/:dojoId" element={<Private Component={Dojo} />} />
            <Route exact path="/members/:dojoId" element={<Private Component={Members} />} />
            <Route exact path="/subscriptions/:dojoId" element={<Private Component={Subscriptions} />} />
            <Route exact path="/person/:personId" element={<Private Component={Person} />} />
            <Route exact path="/person/:personId/edit" element={<Private Component={Person} />} />
            <Route exact path="/discipline/:disciplineId" element={<Private Component={Discipline} />} />
            <Route exact path="/discipline/topics/:disciplineId" element={<Private Component={DisciplineTopics} />} />
            <Route exact path="/discipline/students/:disciplineId" element={<Private Component={DisciplineStudents} />} />
            <Route exact path="/discipline/trainers/:disciplineId" element={<Private Component={DisciplineTrainers} />} />
            <Route exact path="/discipline/statistics/:discipline_Id/:user_Id" element={<Private Component={DisciplineUserStatistics} />} />
            <Route exact path="/discipline/statistics/:disciplineId/" element={<Private Component={DisciplineStatistics} />} />
            <Route exact path="/discipline/exams/:disciplineId" element={<Private Component={DisciplineExams} />} />
            <Route exact path="/discipline/exams/:disciplineId/:refresh" element={<Private Component={DisciplineExams} />} />
            <Route exact path="/discipline/grades/:disciplineId/" element={<Private Component={DisciplineGrades} />} />
            <Route exact path="/exam/:examId/:edit" element={<Private Component={Exam} />} />
            <Route exact path="/exam/:examId" element={<Private Component={Exam} />} />
            <Route exact path="/session/:sessionId/:edit" element={<Private Component={Session} />} />
            <Route exact path="/session/:sessionId" element={<Private Component={Session} />} />

            <Route exact path="/memberships/types" element={<Private Component={MembershipTypes} />} />
            <Route exact path="/memberships" element={<Private Component={Memberships} />} />
            <Route exact path="/memberships/payments" element={<Private Component={Payments} />} />
          </Routes>
        </PageContainer>
      </Fragment>
    </Router>
  );
}