import React, { Fragment } from 'react';
import Selectable from '../components/selectable';




export default function SelectableTopicList({ topics, selectHandler, preselected }) {

    function select(topicId, selected) {

        selectHandler(topicId, selected);
    };

    function isPreselected(topic) {
        var i;
        for (i = 0; i < preselected.length; i++) {
            if (preselected[i].id === topic.id) {
                return true;
            }
        }
        return false;
    }



    function Topic({ topic }) {
        return (
            <>
                <div className="trainerCard-Title">{topic.name}</div>
            </>
        )
    }



    return (
        <Fragment>
            <div className="card_list">
                {topics.map(topic => (
                    <Selectable key={topic.id} component={<Topic topic={topic} />} selectionToggleCallback={select} preSelected={() => isPreselected(topic)} id={topic.id} />
                ))}
            </div>
        </Fragment>
    );

}
