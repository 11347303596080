import { AbilityBuilder, Ability } from '@casl/ability'
import PersonOBJ from '../DataClasses/PersonOBJ';
import store from '../store';


const ability = new Ability()

store.subscribe(() => {

    ability.update(defineRulesFor())

});


function defineRulesFor() {

    const { can, rules } = new AbilityBuilder()

    let isTrainer = store.getState().currentUser.isTrainer
    let teaches = store.getState().currentUser.teaches.map((t) => parseInt(t.id));
    let studies = store.getState().currentUser.studies.map((t) => parseInt(t.id));
    var allDisciplines = teaches;
    allDisciplines = allDisciplines.concat(studies)
    

    let admin = store.getState().currentUser.isAdmin
    let superuser = store.getState().currentUser.isSuperuser
    let dojoId = store.getState().currentUser.dojoId
    let userId = store.getState().currentUser.id
    
    if (admin || superuser) {
        can('add', 'discipline');
        can('startsession', 'DisciplineOBJ');
        can('editDiscipline', 'DisciplineOBJ');
        can('editsession', 'DisciplineOBJ');
        can('edittopics', 'DisciplineOBJ');
        can('startexam', 'DisciplineOBJ');
        can('editexam', 'DisciplineOBJ');
        can('editgrades', 'DisciplineOBJ');
        can('add', 'trainer');
        can('view', 'DisciplineOBJ')
        can('edit', 'PersonOBJ');
    }else {
        can('startsession', 'DisciplineOBJ', { id: { $in: teaches } });
        can('editDiscipline', 'DisciplineOBJ', { id: { $in: teaches } });
        can('editsession', 'DisciplineOBJ', { id: { $in: teaches } });
        can('edittopics', 'DisciplineOBJ', { id: { $in: teaches } });
        can('startexam', 'DisciplineOBJ', { id: { $in: teaches } });
        can('editexam', 'DisciplineOBJ', { id: { $in: teaches } });
        can('editgrades', 'DisciplineOBJ', { id: { $in: teaches } });
        can('view', 'DisciplineOBJ', { id: { $in: allDisciplines } });
        can('edit', 'PersonOBJ', { id: userId });
    }
    return rules

}


export default ability