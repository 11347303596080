import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react';
import config from 'react-global-configuration';
import gql from 'graphql-tag';
import Cookies from 'js-cookie'

import { useDispatch } from 'react-redux';
import { addFeatures,setFeaturesLoaded,setDojoFeaturesLoaded } from '../storeSlices/featureSlice';
import store from '../store';
import Loading from './loading';

const GET_DOJO_FEATURES = gql`
query getFeatures{
  dojoFeatures{
    id,
    feature{
      description
    }
    enabled
  }
}
`;

export const GET_FEATURES = gql`
query getFeatures {
  features{
    description,
    id,
    enabled
  }
}
`;



export default function Features(props) {



  const dispatch = useDispatch()

  const [featuresLoading, setFeaturesLoading] = useState(false)





  const [loadDojoFeatures, { called, loading: dojo_features_loading, data }] = useLazyQuery(
    GET_DOJO_FEATURES,
    {
      variables: { "id": store.getState().currentUser.dojoId },
      skip: store.getState().currentUser.dojoId===null,
      onCompleted: (data) => {
        let dojo_features = {}
        for (let feature of data.dojoFeatures) {
          dojo_features[feature.feature.description] = feature.enabled
        }

        dispatch(addFeatures(dojo_features))
        
      },
      onError: (error) => {
        console.log(error)
      }
    });


  store.subscribe(() => {

    if (store.getState().currentUser.dojoId!==null && !store.getState().features.loadedDojoFeatures) {

      dispatch(setDojoFeaturesLoaded(true))
      loadDojoFeatures();
    }
  });



  useEffect(() => {
    setFeaturesLoading(true)

    const url = config.get('featureUrl');
    fetch(`${url}`, {
      headers: {
        "X-CSRF-Token": Cookies.get("csrftoken"),
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        let dojo_features = {}
        for (let feature of json) {
          if (!dojo_features[feature.fields.description]) {
            dojo_features[feature.fields.description] = feature.fields.enabled;
          }
        }
        dispatch(addFeatures(dojo_features))
        dispatch(setFeaturesLoaded(true))
        setFeaturesLoading(false)
      });

  }, [])


  if (dojo_features_loading || featuresLoading) {
    return <Loading />
  }


  return (
    props.children
  )


}

export function enabled(featureDescription) {
  let features = store.getState().features.features
  return features[featureDescription]
}
