
import React, { useState, useContext } from 'react';
import { Text } from '../../containers/Language';
import gql from 'graphql-tag';
import { Loading } from '../../components';
import { useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux';
import { getIntervalString } from './membershiptypes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faSave
} from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from '../../containers/Language';
import { DatePicker } from "react-tempusdominus-bootstrap";
import { Link } from "react-router-dom";


export default function Memberships() {

    const dojoId = useSelector((state) => state.currentUser.dojoId)

    const MEMBERS_DATA = gql`
    fragment member on PersonType{
           __typename,
            firstName,
            lastName,
            id,
        	membership{
                membershipType{
                    id,
                    price{
                        amount,
                        currency{
                          name
                        }
                    },
                    interval
                },
                startDate
            }
    }
    `;

    const GET_DOJO_STUDENTS = gql`
    query Dojo($dojoId: ID!){
      dojo(id: $dojoId){
            members{
              edges{
                node{
                  ...member
                }
              }
              
            },
            membershipTypes{
                id,
                price{
                    asString
                },
                interval
            }
          }
    }
    ${MEMBERS_DATA}
    `;


    function DojoMember({ member, membershipTypes }) {

        const languageContext = useContext(LanguageContext);

        const [edit, setEdit] = useState(false)
        const [selectedTypeID, setSelectedTypeID] = useState(member.membership ? member.membership.membershipType.id : "")
        const [startDate, setStartDate] = useState(member.membership ? new Date(member.membership.startDate) : new Date())

        function onChangeStartDate(event) {
            setStartDate(new Date(event.date))
        }

        const CREATE_MEMBERSHIP = gql`
        mutation createMembership($input:MembershipInput!){
            createMembership(input:$input){
                ok,
                membership{
                    membershipType{
                        id,
                        price{
                            amount,
                            currency{
                                name
                            }
                        },
                        interval
                    },
                    startDate
                }
            }
        }`;


        const [create, { loading, error }] = useMutation(
            CREATE_MEMBERSHIP
        );


        function save() {
            setEdit(false)
            var input = {
                membershipId: -1,
                memberId: member.id,
                membershipTypeId: selectedTypeID,
                startDate: startDate.toISOString()
            }

            if (selectedTypeID !== "") {
                create({ variables: { input: input } });
            }

        }

        if (edit) {
            return (
                <>
                    <div>
                        {member.firstName} {member.lastName}
                    </div>
                    <div>

                        <select className='form-select form-select-50'
                            value={selectedTypeID.id}
                            onChange={e => {
                                setSelectedTypeID(e.target.value)
                            }}
                        >
                            <option key={"null_key"} value={""}>
                                {languageContext.dictionary["select"]}
                            </option>
                            {membershipTypes.map(membershipType => (
                                <option key={`membershipType_${membershipType.id}`} value={membershipType.id}>
                                    {membershipType.price.asString} {getIntervalString(membershipType.interval)}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div>
                        <DatePicker
                            date={startDate}
                            readOnly={false}
                            onChange={onChangeStartDate}
                            format="DD/MM/YYYY"
                        />
                    </div>
                    <div>
                        <button className='table_button' onClick={() => { save() }}><FontAwesomeIcon icon={faSave} className="mr-2 table_edit" /></button>
                    </div>
                </>
            );
        } else {
            let currentType = membershipTypes.filter(mt => mt.id === selectedTypeID)[0]
            return (
                <>
                    <div>
                        {member.firstName} {member.lastName}
                    </div>
                    <div>
                        {currentType != undefined ?
                            <span>
                                {currentType.price.asString} &nbsp;
                                {getIntervalString(currentType.interval)}
                            </span> : <></>}
                    </div>
                    <div>
                        {member.membership ? startDate.toLocaleDateString() : ""}
                    </div>
                    <div>
                        <button className='table_button' onClick={() => { setEdit(true) }}><FontAwesomeIcon icon={faEdit} className="mr-2 table_edit" /></button>
                    </div>
                </>
            )
        }
    }


    function MemberTable({ members, membershipTypes }) {

        const languageContext = useContext(LanguageContext);

        const [allMember, setAllmembers] = useState(members);
        const [offset, setOffset] = useState(0)
        const [entriesPerPage, setEntriesPerPage] = useState(10)
        const [filteredMembers, setFilteredMembers] = useState(members)

        function next() {
            var newOffset = offset + entriesPerPage >= filteredMembers.length ? offset : offset + entriesPerPage;
            setOffset(newOffset)
        }

        function previous() {
            var newOffset = offset - entriesPerPage < 0 ? 0 : offset - entriesPerPage;
            setOffset(newOffset)
        }

        return (
            <>
                <div className='memberships_table'>
                    <div className='membership_types_header'><Text tid="member" /></div>
                    <div className='membership_types_header'><Text tid="membershipType" /></div>
                    <div className='membership_types_header'><Text tid="membershipSince" /></div>
                    <div className='membership_types_header'></div>
                    {filteredMembers.slice(offset, offset + entriesPerPage).map(node => <DojoMember key={node.node.id} member={node.node} membershipTypes={membershipTypes} />)}
                </div>
                <div className='memberships_table_commands'>
                    <button className="btn btn-primary m-2" onClick={() => previous()}>{'<'}</button>
                    <Text tid='page' />&nbsp;
                    <strong>
                        {Math.floor(offset / entriesPerPage) + 1} of {Math.ceil(filteredMembers.length / entriesPerPage)}
                    </strong>{' '}
                    <button className="btn btn-primary m-2" onClick={() => next()}>{'>'}</button>

                    <select className='form-select form-select-50'
                        value={entriesPerPage}
                        onChange={e => {
                            setEntriesPerPage(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {languageContext.dictionary["show"]} {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        )
    }

    const { data, loading, error, refetch } = useQuery(
        GET_DOJO_STUDENTS,
        {
            variables: { dojoId }
            , fetchPolicy: "cache-and-network"
        },
    );

    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;


    var members = data.dojo.members.edges
    return (
        <>
            <div className="dm-container">
                <Link className='back' to={`/dojo/${dojoId}`} >
                    <Text tid="backto" /> <Text tid="ManageDojo" />
                </Link>
                <div className='content-block'>
                    <h2 className='content-block-header'>  <Text tid="manageMemberships" /></h2>

                    <MemberTable members={members} membershipTypes={data.dojo.membershipTypes} />

                </div>
            </div>
        </>
    )
}