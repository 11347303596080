import React from 'react';
import { Text } from '../containers/Language';
import { enabled } from '../components/features';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";

import { useSelector } from 'react-redux';

export default function Header({ pageNav }) {

  let  currentUser = useSelector((state) => state.currentUser.dojoId)

  function renderProfileMenu() {
    if (currentUser) {
      return (

        <div className='splash_main_header'>
          <div></div>
          <Link to="/landing" >
            <li className={"icon_li_splash"}>
              <FontAwesomeIcon icon={faClipboard} />
              <Text tid="Dashboard" />
            </li>
          </Link>
        </div>


      );
    }
  }

  function renderLogin() {
    const regen = enabled("registration");

    if (currentUser === null) {
      return (
        <div className='splash_main_header'>
          <div>
            <Link to="/">
              <li className={"icon_li_splash"}><Text tid="DojoTrainer" /></li>
            </Link>
          </div>
          <div className="splash_main_header_right">
            {regen ? <Link to="/register/free"> <li className={"icon_li_splash"}><Text tid="Register" /></li></Link> : ""}

            <Link to="/login"> <li className={"icon_li_splash"}><Text tid="Login" /></li></Link>
          </div>
        </div>
      )
    }
  }

  return (
    <header className="header">

      {renderProfileMenu()}

      {renderLogin()}

    </header>

  );
}

