import React from "react";
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from './components';
import PrivateLayout from "./components/private_layout";
import { Navigate } from "react-router-dom";

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const VERIFY_TOKEN = gql`
query verifyToken($token: String!) {
  verifyToken(token: $token) {
    payload
  }
}
`;

const Private = ({Component}) => {
  useQuery(IS_LOGGED_IN);

  const token = localStorage.getItem('token');
  const { verify_data, loading, error } = useQuery(
    VERIFY_TOKEN,
    {
      variables: { token },
      onError: (error) => {
        if (error.message === "Signature has expired") {
          localStorage.removeItem('token')
        }
      }
    },

  );


  var isLoggedIn = !!localStorage.getItem('token')

  if (loading) return <Loading />;
  if (error) return  <Navigate to="/login" />

  return isLoggedIn ? <PrivateLayout><Component /></PrivateLayout> : <Navigate to="/login" />
  

}

export default Private;
