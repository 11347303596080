import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Loading } from "../components";
import StudentList from "../containers/student-list";
import SelectableStudentList from "../containers/selectable-student-list";
import SelectableTopicList from "../containers/selectable-topic-list";
import { Link } from "react-router-dom";
import { enabled } from "../components/features";
import { DateTimePicker } from "react-tempusdominus-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { Text } from "../containers/Language";
import { useNavigate } from "react-router";
import { Can } from "../security/can";
import DisciplineOBJ from "../DataClasses/Discipline";
import { Navigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import { toggleDirty } from "../storeSlices/sessionsSlice";
import { useDispatch } from "react-redux";
import { sort_by_current_grade } from "../logic/sorting";

import "react-datetime/css/react-datetime.css";

export const GET_SESSION = gql`
  query Session($sessionId: ID!) {
    trainingSession(id: $sessionId) {
      startDateTime
      endDateTime
      description
      participants {
        edges {
          node {
            firstName
            lastName
            id
            avatar {
              id
            }
            grades {
              id
              description
              rank
              discipline {
                id
              }
            }
          }
        }
      }
      topics {
        id
        name
        category {
          name
          id
        }
      }
      discipline {
        name
        icon {
          id
        }
        grades {
          id
          description
          rank
        }
        id
        students {
          edges {
            node {
              firstName
              lastName
              id
              avatar {
                id
              }
              grades {
                id
                description
                rank
                discipline {
                  id
                }
              }
            }
          }
        }
        categories {
          name
          id
          topics {
            name
            id
          }
        }
        dojo {
          name
          id
        }
      }
    }
  }
`;

export const TOGGLE_PARTICIPATION = gql`
  mutation ToggleParticipation($sessionId: Int!, $studentId: Int!) {
    toggleParticipation(sessionId: $sessionId, studentId: $studentId) {
      ok
    }
  }
`;

export const TOGGLE_TOPIC = gql`
  mutation ToggleTopic($sessionId: Int!, $topicId: Int!) {
    toggleTopic(sessionId: $sessionId, topicId: $topicId) {
      ok
    }
  }
`;

const UPDATE_SESSION = gql`
  mutation UpdateSession($input: TrainingSessionInput!) {
    updateSession(input: $input) {
      ok
    }
  }
`;

const DELETE_SESSION = gql`
  mutation DeleteSession($id: Int!) {
    deleteSession(id: $id) {
      ok
    }
  }
`;

export default function Session() {
  const { sessionId, edit } = useParams();

  const dispatch = useDispatch();

  const [toggleParticipation] = useMutation(
    TOGGLE_PARTICIPATION
    //  {
    //   variables: { sessionId },
    //   refetchQueries: [
    //     {
    //       query: GET_SESSION,
    //       variables: { sessionId },
    //     },
    //   ]
    // }
  );

  const [toggleTopic] = useMutation(
    TOGGLE_TOPIC
    //{
    //  variables: { sessionId },
    //  refetchQueries: [
    //    {
    //      query: GET_SESSION,
    //     variables: { sessionId },
    //   },
    // ]
    // }
  );
  const navigate = useNavigate();

  const [updateSession] = useMutation(UPDATE_SESSION, {
    refetchQueries: [
      {
        query: GET_SESSION,
        variables: { sessionId },
      },
    ],
    onCompleted: () => {
      dispatch(toggleDirty());
      navigate("/session/" + sessionId);
    },
  });

  const [deleteSessionMutation] = useMutation(DELETE_SESSION, {
    onCompleted: () => {
      dispatch(toggleDirty());
      navigate("/discipline/" + session.discipline.id);
    },
  });

  function selectionHandler(userId, selected) {
    if (selected) {
      setParticipantCount(participantCount + 1);
    } else {
      setParticipantCount(participantCount - 1);
    }
    toggleParticipation({
      variables: { sessionId: sessionId, studentId: userId },
    });
  }

  function topicSelectHandler(topicId, selected) {
    toggleTopic({ variables: { sessionId: sessionId, topicId: topicId } });
  }

  const { data, loading, error } = useQuery(GET_SESSION, {
    variables: { sessionId },
  });

  const [startdatetime, setStartDatetime] = useState("");
  const [enddatetime, setEndDatetime] = useState("");
  const [description, setDescription] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [categories, setCategories] = useState([]);
  const [participantCount, setParticipantCount] = useState(-1); //initialioze with -1 so we have a gatekeeper for setting initial vlaue only once

  const onChangeDescription = (content, editor) => {
    setDescription(content);
  };

  const onChangeStartDatetime = (event) => {
    setStartDatetime(event.date);
  };

  const onChangeEndDatetime = (event) => {
    setEndDatetime(event.date);
  };

  const save = (event) => {
    const date = new Date(startdatetime);
    const date2 = new Date(enddatetime);
    var input = {
      id: sessionId,
      startDateTime: date.toISOString(),
      endDateTime: date2.toISOString(),
      description: description,
    };

    updateSession({ variables: { input: input } });
  };

  const deleteSession = (event) => {
    deleteSessionMutation({ variables: { id: sessionId } });
  };

  function get_grade(grades, d_id) {
    for (let element of grades) {
      if (parseInt(element.discipline.id) == d_id) {
        return element;
      }
    }
  }

  function current_grades(students, d_id) {
    for (let student of students) {
      student.current_grade = get_grade(student.grades, d_id);
    }
  }

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const session = data.trainingSession;

  if (session === null) return <Navigate to="/" />;

  const discipline = session.discipline;
  const all_grades = discipline.grades;
  const students = cloneDeep(discipline.students.edges.map((e) => e.node));
  current_grades(students, discipline.id);

  students.sort(sort_by_current_grade);

  const participants = cloneDeep(session.participants.edges.map((e) => e.node));
  current_grades(participants, discipline.id);
  participants.sort(sort_by_current_grade);
  if (participantCount === -1) {
    setParticipantCount(participants.length);
  }

  var topics = [];
  var categoryTopics = new Map();

  if (enabled("session_topics")) {
    topics = session.topics;

    for (var topic of topics) {
      if (categoryTopics.has(topic.category)) {
        var t = categoryTopics.get(topic.category);
        t.push(topic);
        categoryTopics.set(topic.category, t);
      } else {
        categoryTopics.set(topic.category, [topic]);
      }
    }
  }

  const startdate = new Date(session.startDateTime);
  const formatedStartDate = startdate.toLocaleDateString();
  const formatedStartTime = startdate.getHours() + ":" + startdate.getMinutes();

  const enddate = new Date(session.endDateTime);
  const formatedEndTime = enddate.getHours() + ":" + enddate.getMinutes();

  if (!initialized) {
    setStartDatetime(session.startDateTime);
    setEndDatetime(session.endDateTime);
    setDescription(session.description);
    if (enabled("session_topics")) {
      setCategories(session.discipline.categories);
    }
    setInitialized(true);
  }

  function session_description() {
    if (edit === "edit") {
      return (
        <>
          <h2 className="mt-3">
            <Text tid="Description" />
          </h2>
          <Editor
            value={description || ""}
            placeholder="Description"
            onEditorChange={onChangeDescription}
            apiKey="26vxbkpv7mim6n9f2eehf6h7o60qw923gyhkv0ub0yl0hm90"
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
            }}
          />
          {categories.length > 0 ? (
            <h2 className="mt-3">
              <Text tid="SelectTopics" />
            </h2>
          ) : (
            ""
          )}
          {categories.length > 0 ? (
            <div className="accordion" id={`categories`}>
              {categories.map((category) => (
                <div
                  className="accordion-item"
                  id={`cat_${category.id}`}
                  key={`cat_${category.id}`}
                >
                  <div
                    className="accordion-header"
                    id={`cat_head_${category.id}`}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse_${category.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse_${category.id}`}
                    >
                      {category.name}
                    </button>
                  </div>
                  <div
                    id={`collapse_${category.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`cat_head_${category.id}`}
                    data-bs-parent="#categories"
                  >
                    <div className="accordion-body">
                      <SelectableTopicList
                        topics={category.topics}
                        selectHandler={topicSelectHandler}
                        preselected={topics}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <div>
          <div
            className="mb-2"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          {topics.length > 0 ? (
            <h3>
              <Text tid="CoveredTopics" />
            </h3>
          ) : (
            ""
          )}
          {[...categoryTopics.keys()].map((key) => (
            <div key={`cat_${key.id}`}>
              <div className="discipline-row">
                <h4>{key.name}:</h4>
              </div>
              <div className="discipline-row">
                {categoryTopics.get(key).map((topic) => (
                  <div className="topic" key={`top_${topic.id}`}>
                    {" "}
                    {topic.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  function dateTimeLabel() {
    if (edit === "edit") {
      return (
        <div className="dateSelectContainer">
          <div>
            <Text tid="StartDate" />:
            <DateTimePicker
              date={startdatetime}
              readOnly={false}
              onChange={onChangeStartDatetime}
              format="DD/MM/YYYY HH:mm"
              inline={true}
            />
          </div>
          <div>
            <Text tid="EndDate" />:
            <DateTimePicker
              date={enddatetime}
              readOnly={false}
              onChange={onChangeEndDatetime}
              format="DD/MM/YYYY HH:mm"
              inline={true}
            />
          </div>
        </div>
      );
    } else {
      return (
        <span>
          {formatedStartDate}: {formatedStartTime} - {formatedEndTime}
        </span>
      );
    }
  }

  if (edit === "edit") {
    return (
      <Fragment>
        <Can I="editsession" on={new DisciplineOBJ(discipline.id)}>
          <div className="dm-container">
            <Link className="back" to={`/discipline/${discipline.id}`}>
              <Text tid="backto" /> {discipline.name}
            </Link>
            <div className="content-block">
              <h2 className="content-block-header">
                {discipline.name} <Text tid="Session" />: {dateTimeLabel()}
              </h2>

              <h2 className="mt-3">
                <Text tid="SelectParticipatingStudents" />({participantCount}/
                {students.length}):
              </h2>

              <SelectableStudentList
                selectHandler={selectionHandler}
                students={students}
                preselected={participants}
                grades={all_grades}
              />

              {session_description()}

              <div className="session_command_row">
                <button className="btn btn-primary" onClick={save}>
                  <Text tid="Save" />
                </button>
                <button className="btn delete-button" onClick={deleteSession}>
                  <Text tid="delete" />
                </button>
              </div>
            </div>
          </div>
        </Can>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="dm-container">
          <Link className="back" to={`/discipline/${discipline.id}`}>
            <Text tid="backto" /> {discipline.name}
          </Link>

          <div className="content-block">
            <h2 className="content-block-header">
              {discipline.name} <Text tid="Session" />: {formatedStartDate}{" "}
              {formatedStartTime} - {formatedEndTime}
              <Can I="editsession" on={new DisciplineOBJ(discipline.id)}>
                <Link to={`/session/${sessionId}/edit`}>
                  {" "}
                  <Text tid="edit" />
                </Link>
              </Can>
            </h2>
            {session_description()}

            <h3>
              <Text tid="Participants" />({participants.length}/
              {students.length}):
            </h3>
            <StudentList students={participants} />
          </div>
        </div>
      </Fragment>
    );
  }
}
