import React, { useState } from 'react';
import config from 'react-global-configuration';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { addFile } from '../storeSlices/secureFileSlice';
import store from '../store';




export function SecureImage({ fileId, className,alt }) {

  const dispatch = useDispatch();
  const [img, setImg] = useState("");

  if (fileId != null) {
    const url = config.get('mediaUrl') + fileId;
    let all_files = store.getState().files.files;
    if (fileId in all_files) {
      return (<img className={className} src={all_files[fileId]} alt={"Loading..."} />);
    }

    fetch(`${url}`, {
      headers: {
        authorization: "JWT " + localStorage.getItem("token"),
        "X-CSRF-Token": Cookies.get("csrftoken"),
      },
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        const imgURL = URL.createObjectURL(blob);
        dispatch(addFile({ id: fileId, file: imgURL }));
        setImg(imgURL);
      });
    return (<img className={className} src={img} alt={"Loading..."} />);
  } else {
    return (<img className={className} alt='_Image' src={alt} />);
  }
}
