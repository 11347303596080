import React from 'react';
import { useNavigate } from 'react-router-dom';

import DisciplineIcon from '../assets/icons/discipline.svg';

import { SecureImage } from './SecureImage';




export default function DisciplineCard({ discipline, clicktarget, foruser }) {
    const navigate = useNavigate();

    const current_grade_truncate = discipline.current_grade?discipline.current_grade.length > 20 ? `${discipline.current_grade.substring(0, 20)} ...` : discipline.current_grade:"";

    return (
        <div className="disciplineCard card m-1" onClick={() => navigate(`/${clicktarget}/${discipline.id}/${foruser}`)} id={`card_${discipline.id}`}>
            <div className="card-body discipline-card-body">
                    <SecureImage fileId={discipline.icon?discipline.icon.id:null} className="cardImage" alt={DisciplineIcon}/>
                <div className="descipline-card-title">
                    {discipline.name}
                </div>

                <div className="descipline-card-grade">
                    {current_grade_truncate}
                </div>
            </div>
        </div>
    );
}
