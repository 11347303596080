import React, { Fragment } from 'react';
import Header from '../components/header';
import logo from '../assets/logo.svg'
import { Text } from '../containers/Language';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faToriiGate,
    faStopwatch,
    faCalendarCheck,
    faClipboardList,
    faRankingStar,
    faUser,
    faGraduationCap,
    faStar,
    faMoneyCheckDollar

} from "@fortawesome/free-solid-svg-icons";



export default function Splash() {

    return <Fragment>
        <div className='splash-container'>
            <Header />
            <div className="dm-container">
                <div className="dm-container">
                    <div className='spash_container'>
                        <div className='splash_logo_container'>
                            <img className='splash_logo' src={logo} />
                        </div>
                        <h1 className='splash_title'>
                            <Text tid="splash_title" /><br />
                            <div className="call_to_action_bar">
                                <a href="register/free" className="btn btn-primary call_to_action"><Text tid="RegisterNow" /></a>
                                <Text tid="or" />
                                <a href="login" className="btn btn-primary call_to_action"><Text tid="Login" /></a>
                            </div>
                        </h1>

                        <ul className='fetures_list'>
                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faToriiGate} size="6x" />
                                    <Text tid="creatcustomdiscipline" />
                                </div>
                            </li>
                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faCalendarCheck} size="6x" />
                                    <Text tid="createsessions" />
                                </div>
                            </li>
                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faStopwatch} size="6x" />
                                    <Text tid="trackstudents" />
                                </div>
                            </li>

                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faChartLine} size="6x" />
                                    <Text tid="trackprogress" />
                                </div>
                            </li>
                            <li className='feature_pro'>
                                <div>
                                    <FontAwesomeIcon icon={faClipboardList} size="6x" />
                                    <Text tid="createtrainingtopics" />
                                </div>
                            </li>

                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faRankingStar} size="6x" />
                                    <Text tid="createExams" />
                                </div>
                            </li>

                            <li className='feature_pro'>
                                <div>
                                    <FontAwesomeIcon icon={faUser} size="6x" />
                                    <Text tid="unlimited_students" />
                                </div>
                            </li>

                            <li className='feature_pro'>
                                <div>
                                    <FontAwesomeIcon icon={faGraduationCap} size="6x" />
                                    <Text tid="addtrainerswithlogin" />
                                </div>
                            </li>

                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faMoneyCheckDollar} size="6x" />
                                    <Text tid="membershipManagement" />
                                </div>
                            </li>


                            <li>
                                <div>
                                    <FontAwesomeIcon icon={faStar} size="6x" />
                                    <Text tid="moresoon" />
                                </div>
                            </li>

                        </ul>
                    </div>

                    <div className='spash_container'>
                        <h1 className='splash_title'><Text tid="features" /></h1>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faToriiGate} size="6x" />
                            <div><Text tid="creatcustomdisciplinedetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faCalendarCheck} size="6x" />
                            <div><Text tid="createsessionsdetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faStopwatch} size="6x" />
                            <div><Text tid="trackstudentsdetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faChartLine} size="6x" />
                            <div><Text tid="trackprogressdetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} size="6x" />
                            <div><Text tid="membershipManagementDetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faClipboardList} size="6x" />
                            <div><Text tid="createtrainingtopicsdetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faRankingStar} size="6x" />
                            <div><Text tid="createExamsdetails" /></div>
                        </div>
                        <div className='feature'>
                            <FontAwesomeIcon icon={faGraduationCap} size="6x" />
                            <div><Text tid="addtrainerswithlogindetails" /></div>
                        </div>
                    </div>

                    <div className='spash_container'>
                        <h1 className='splash_title'>
                            <Text tid="compare_plans" />
                        </h1>
                        <div className='comparison'>
                            <div className='comparison_head'></div>
                            <div className='comparison_head'><Text tid="Free" /></div>
                            <div className='comparison_head feature_pro'><Text tid="pro" /></div>

                            <div className='comparison_title'><Text tid="numofdisc" /></div>
                            <div className='comparison_value highlight'><Text tid="unlimited" /></div>
                            <div className='comparison_value highlight'><Text tid="unlimited" /></div>

                            <div className='comparison_title'><Text tid="tracksessions" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>

                            <div className='comparison_title'><Text tid="trackparticipation" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>

                            <div className='comparison_title'><Text tid="trackprogress" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>

                            <div className='comparison_title'><Text tid="createtrainingtopics" /></div>
                            <div className='comparison_value'><Text tid="no" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>

                            <div className='comparison_title'><Text tid="createExams" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            
                            <div className='comparison_title'><Text tid="membershipManagement" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>
                            <div className='comparison_value'><Text tid="yes" /></div>

                            <div className='comparison_title'><Text tid="numstudents" /></div>
                            <div className='comparison_value'>50</div>
                            <div className='comparison_value highlight'><Text tid="unlimited" /></div>

                            <div className='comparison_title'><Text tid="numtrainer" /></div>
                            <div className='comparison_value'>1</div>
                            <div className='comparison_value highlight'><Text tid="unlimited" /></div>

                            <div className='comparison_title'><Text tid="price" /></div>
                            <div className='comparison_value highlight'><Text tid="free" /></div>
                            <div className='comparison_value highlight'>25 € / <Text tid="month" /></div>


                            <div className='comparison'></div>
                            <div className='comparison_action'> <a href="register/free" className="btn btn-primary"><Text tid="startforfree" /></a></div>
                            <div className='comparison_action'><a href="register/pro" className="btn btn-primary"><Text tid="RegisterNow" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>;
}

