
import React, { useState, useContext } from 'react';
import { Text } from '../../containers/Language';
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../../components';
import { LanguageContext } from '../../containers/Language';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

export function getIntervalString(interval) {
    switch (interval) {
        case 'M':
            return "monthly"
        case 'Q':
            return "quarterly"
        case 'B':
            return 'biannual'
        case 'Y':
            return 'yearly'
        default:
            return 'invalidinterval'
    }
}


function CreateMembershiptType({ currencies, reload }) {
    const [edit, setEdit] = useState(false)
    const [interval, setInterval] = useState("")
    const [currency, setCurrency] = useState("")
    const [price, setPrice] = useState(0)

    const dojo_id = useSelector((state) => state.currentUser.dojoId)

    const languageContext = useContext(LanguageContext);

    const CREATE_MEMBERSHIPTYPE = gql`
    mutation createMembershiptype($input:MembershipttypeInput!){
        createMembershipType(input:$input){
            ok
      }
    }`;


    const [create, { loading, error }] = useMutation(
        CREATE_MEMBERSHIPTYPE, {
        onCompleted(data) {
            reload();
        }
    }
    );




    function save() {
        let membership_input = {
            "id": 0,
            "price": price,
            "currency": currency,
            "interval": interval,
            "dojoId": dojo_id
        }
        create({ variables: { input: membership_input } });

        setEdit(false)
    }

    if (edit) {
        return (
            <>
                <div>
                    <select
                        className="form-select"
                        onChange={e => setInterval(e.target.value)}
                        value={interval}
                    >
                        <option key="interval_none" value="0">{languageContext.dictionary["select"]}</option>
                        <option key="interval_m" value="M">{languageContext.dictionary["monthly"]}</option>
                        <option key="interval_q" value="Q">{languageContext.dictionary["quarterly"]}</option>
                        <option key="interval_b" value="B">{languageContext.dictionary["biannual"]}</option>
                        <option key="interval_y" value="Y">{languageContext.dictionary["yearly"]}</option>

                    </select>
                </div>
                <div className='compund-form-field'>
                    <input type="number"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                    />

                    <select
                        className="form-select form-select-50"
                        onChange={e => setCurrency(e.target.value)}
                        value={currency}
                    >
                        <option key="currency_none" value="0">{languageContext.dictionary["select"]}</option>
                        {
                            currencies.map(c => {
                                return (
                                    <option key={`currency_${c.short}`} value={c.short}>{c.short}</option>
                                )
                            })

                        }
                    </select>



                </div>
                <div>
                    <button className="btn btn-primary" onClick={e => save()}><Text tid="save" /></button>
                    <button className="btn btn-primary m-1" onClick={e => setEdit(false)}><Text tid="Cancel" /></button>
                </div>

            </>
        )
    } else {
        return (
            <>
                <div><button className="btn btn-primary" onClick={e => setEdit(true)}><Text tid="createMembershiptType" /></button></div>
            </>
        )
    }
}

function MembershipType({ membershiptType, currencies, reload }) {

    const [edit, setEdit] = useState(false)
    const [interval, setInterval] = useState(membershiptType.interval)
    const [currency, setCurrency] = useState(membershiptType.priceCurrency)
    const [price, setPrice] = useState(membershiptType.price.amount)
    const [deleteType, setDelete] = useState(false)

    const languageContext = useContext(LanguageContext);

    const UPDATE_MEMBERSHIPTYPE = gql`
    mutation updateMembershiptype($input:MembershipttypeInput!){
        updateMembershipType(input:$input){
            ok
      }
    }`;

    const DELETE_MEMBERSHIPTYPE = gql`
    mutation deleteMembershiptype($id:Int!){
        deleteMembershipType(id:$id){
            ok
      }
    }`;

    const [update, { loading, error }] = useMutation(
        UPDATE_MEMBERSHIPTYPE
    );

    const [deleteMutation, { loading_del, error_del }] = useMutation(
        DELETE_MEMBERSHIPTYPE, {
        onCompleted(data) {
            reload();
        }
    })



    function save() {
        let membership_input = {
            "id": membershiptType.id,
            "price": price,
            "currency": currency,
            "interval": interval
        }
        update({ variables: { input: membership_input } });
        setEdit(false)
    }

    function deleteMemebrshipType() {
        deleteMutation({ variables: { id: membershiptType.id } })
        setDelete(false)
    }

    function DeleteType() {



        if (deleteType) {
            return (
                <div className='dialog_card'>
                    <div className='dialog_card_inner'>
                        <Text tid="confirmMembershiptTypeDelete" />
                        <button className="btn btn-primary m-1" onClick={e => deleteMemebrshipType()}><Text tid="delete" /></button>
                        <button className="btn btn-primary m-1" onClick={e => setDelete(false)}><Text tid="cancel" /></button>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }

    }

    if (edit) {
        return (
            <>
                <DeleteType />
                <div>
                    <select
                        className="form-select"
                        onChange={e => setInterval(e.target.value)}
                        value={interval}
                    >
                        <option key="interval_m" value="M">{languageContext.dictionary["monthly"]}</option>
                        <option key="interval_q" value="Q">{languageContext.dictionary["quarterly"]}</option>
                        <option key="interval_b" value="B">{languageContext.dictionary["biannual"]}</option>
                        <option key="interval_y" value="Y">{languageContext.dictionary["yearly"]}</option>

                    </select>
                </div>
                <div className='compund-form-field'>
                    <input type="number"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                    />

                    <select
                        className="form-select form-select-50"
                        onChange={e => setCurrency(e.target.value)}
                        value={currency}
                    >
                        {
                            currencies.map(c => {
                                return (
                                    <option key={`currency_${c.short}`} value={c.short}>{c.short}</option>
                                )
                            })

                        }
                    </select>



                </div>
                <div>
                    <button className="btn btn-primary" onClick={e => save()}><Text tid="save" /></button>
                    <button className="btn btn-primary m-1" onClick={e => setEdit(false)}><Text tid="Cancel" /></button>
                </div>
            </>
        )
    } else {
        return (
            <>
                <DeleteType />
                <div><Text tid={getIntervalString(interval)} /></div>
                <div>{price} {currency}</div>
                <div>
                    <button className="btn btn-primary m-1" onClick={e => setEdit(true)}><Text tid="edit" /></button>
                    <button className="btn btn-primary" onClick={e => setDelete(true)}><Text tid="delete" /></button>
                </div>
            </>
        )
    }


}

export default function MembershipTypes() {
    const dojo_id = useSelector((state) => state.currentUser.dojoId)


    const GET_MEMBERSHIPT_TYPES = gql`
    query MemershipTypes{
        membershipTypes{
          id,
          price{
            amount,
            currency{
              name
            }
          }
          interval,
          priceCurrency
        }
        ,
        currencyTypes{
                short,
                long
        }
        
    }
    `;

    const { data, loading, error, refetch } = useQuery(
        GET_MEMBERSHIPT_TYPES,
    );

    if (loading) return <Loading />;
    if (error) return <p>ERROR: {error.message}</p>;

    let membershipTypes = data.membershipTypes;
    let currencies = data.currencyTypes;
    return (<>
        <div className="dm-container">
            <Link className='back' to={`/dojo/${dojo_id}`} >
                <Text tid="backto" /> <Text tid="ManageDojo" />
            </Link>
            <div className='content-block'>
                <h2 className='content-block-header'><Text tid="manageMembershipTypes" /></h2>
                <div className='membership_types'>
                    <div className='membership_types_header'><Text tid="interval" /></div>
                    <div className='membership_types_header'><Text tid="price" /></div>
                    <div className='membership_types_header'></div>

                    {membershipTypes.map(d => (<MembershipType key={`mt_${d.id}`} membershiptType={d} currencies={currencies} reload={refetch} />))}
                    <CreateMembershiptType currencies={currencies} reload={refetch} />
                </div>
            </div>
        </div>
    </>)
}