import React, { Fragment } from 'react';
import DisciplineCard from '../components/discipline-card';

export default function DisciplineList({ disciplines, editable, dojoId, clicktarget, foruser = '' }) {


  return (
    <Fragment>
      <div className="row">
        {disciplines.map(discipline => (
          <DisciplineCard key={discipline.id} discipline={discipline} clicktarget={clicktarget} foruser={foruser} />
        ))}

      </div>

    </Fragment>
  );
}

