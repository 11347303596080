import React, { useState, Suspense, useRef } from 'react';
import { SecureImage } from '../components/SecureImage';
import Profile from '../assets/icons/profile.svg';
import EditIcon from '../assets/icons/edit_pen.svg';
import ReloadIcon from '../assets/icons/repeat.svg';
import CameraRotateIcon from '../assets/icons/camera-rotate.svg';
import { Can } from '../security/can';
import { Text } from '../containers/Language';
import axios from 'axios';
import config from 'react-global-configuration';
import Webcam from "react-webcam";

export default function EditableImage({ fileId, afterSave, personObject }) {

    const [show, setShow] = useState(false);
    const [image, setImage] = useState()
    const inputRef = useRef(null);
    const webcamRef = React.useRef(null);

    const [devices, setDevices] = useState(0)


    navigator.mediaDevices.enumerateDevices().then(devices => { setDevices(devices.filter(({ kind }) => kind === "videoinput").length); })

    const [videoConstraints, setVideoConstraints] = useState({
        width: 1280,
        height: 720,
        facingMode: "user"
    });


    function switch_camera() {
        if (videoConstraints.facingMode === "user") {
            setVideoConstraints({
                width: 1280,
                height: 720,
                facingMode: { exact: "environment" }
            })
        } else {
            setVideoConstraints({
                width: 1280,
                height: 720,
                facingMode: "user"
            })
        }

    }

    function save() {
        const data = new FormData();
        data.append('file', image);

        var axiosInst = axios.create({
            url: config.get('uploadUrl') + "base/",
            method: "put",
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: "JWT " + localStorage.getItem('token'),
            },
        })

        axiosInst.put(config.get('uploadUrl') + 'base/', data).then(function (response) {
            afterSave(response.data.id);
            setShow(false);
        }).catch(function (error) {

        });
    }

    function encodeImageFileAsURL(element) {
        var file = element;
        var reader = new FileReader();
        reader.onloadend = function () {
            setImage(reader.result)
        }
        reader.readAsDataURL(file);
    }

    function onChangeHandler(event) {
        encodeImageFileAsURL(event.target.files[0])
    }


    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc)
        },
        [webcamRef]
    );


    const handleClick = () => {
        inputRef.current.click();
    };

    function Overlay() {

        if (show) {
            console.log(devices)
            return (
                <div className='overlay'>
                    <div className='overlay_inner'>


                        <input style={{ display: 'none' }}
                            type="file"
                            ref={inputRef}
                            id="file-input"
                            onChange={e => onChangeHandler(e)} />

                        {devices > 0 ?
                            <div className='webcam_div'>
                                {image ?
                                    <div className='camer-container'>
                                        <img src={image} className="camera-capture-image" />
                                        <div className='capture-controls'>
                                            <button className="btn btn-primary m-2 camera-capture-button" onClick={(e) => {
                                                e.preventDefault();
                                                setImage('')
                                            }}>
                                                <div className='camera-capture-button-cross'><img src={[ReloadIcon]} alt="retake image" /></div>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className='camer-container'>
                                        <Webcam
                                            audio={false}
                                            width={250}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={videoConstraints}
                                        />
                                        <div className='capture-controls'>
                                            <button className="btn btn-primary m-2 camera-capture-button" onClick={capture}><div className='camera-capture-button-circle'>&nbsp;</div></button>
                                            {devices > 1 ?
                                                <button className="btn btn-primary m-2 camera-capture-button" onClick={(e) => {
                                                    e.preventDefault();
                                                    switch_camera();
                                                }}>
                                                    <div className='camera-capture-button-change-camera'><img src={[CameraRotateIcon]} alt="change camera" /></div>
                                                </button> : ""}
                                        </div>
                                    </div>
                                }
                            </div>
                            : ""}
                        <div>
                            <Text tid='or' />
                        </div>
                        <div className='mb-4'>
                        <button className="btn btn-primary m-2" onClick={e => handleClick()}><Text tid='upload' /></button>
                        </div>
                        <div>
                            <button className="btn btn-primary m-2" onClick={e => save()}><Text tid="save" /></button>
                            <button className="btn btn-primary m-2" onClick={e => setShow(false)}><Text tid="cancel" /></button>
                        </div>

                    </div>
                </div>
            );
        } else {
            return (<></>)
        }

    }

    return (
        <>

            <Overlay />
            <div className="editable-image">
                <div className="avatarEditContainer disciplineImg" onClick={e => setShow(true)}>
                    <Suspense>
                        <SecureImage fileId={fileId ? fileId : null} className="avatar" alt={Profile} />
                    </Suspense>
                    <Can I="edit" this={personObject}>
                        <div className="avatarEditOverlay">
                            <img src={[EditIcon]} alt="edit" />
                        </div>
                    </Can>
                </div>
            </div>
        </>
    );

}