import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';
import { Loading } from '../components';
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";




export const GET_DISCIPLINE_DETAILS = gql`
query Discipline($disciplineId: ID!){
  discipline(id: $disciplineId){
        name,
        id,
        icon{
          id
        },
        dojo{
          id,
          name
        },
        students{
          edges{
            node{
              id
            }
          }
        },
        sessions{
          edges{
            node{
              id,
              dateTime,
            }
          }
        }
      }
    }
`;


export default function DisciplineStatistics() {

  const { disciplineId } = useParams();

  const { data, loading, error } = useQuery(
    GET_DISCIPLINE_DETAILS,
    { variables: { disciplineId } },
  );



  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;

  const discipline = data.discipline;
  if (discipline === null) {
    return (<Navigate to={`/landing`} />);
  }
  const sessions = discipline.sessions.edges.map(e=>e.node);
  const students = discipline.students.edges.map(e=>e.node);

  //var students_per_session = sessions.map(session => ({ x: new Date(session.dateTime), y: session.participants.length }));
  //var last_session_date = new Date(sessions[sessions.length - 1].dateTime);
  //var first_visible_session_date = new Date(sessions[sessions.length - Math.min(sessions.length, 20)].dateTime);


  return (
    <Fragment>
      <div className="cointainer">
        <h3>Number of Sessions</h3>{sessions.length}
        <h3>Number of Students</h3>{students.length}
      </div>
    </Fragment>
  );

/*
  <VictoryChart
  containerComponent={
    <VictoryZoomContainer
      allowZoom={false}
      zoomDomain={{ x: [first_visible_session_date, last_session_date] }}
    />
  }
  crossAxis={true}
>
  <VictoryLine
    style={{
      data: { stroke: colors.accent, strokeWidth: 1 },
    }}
    data={students_per_session}
  />
  <VictoryAxis dependentAxis={true}
    style={{
      axis: { stroke: colors.secondary },
      tickLabels: { fontSize: unit, fill: colors.primary },
    }}
    minDomain={{ x: 0, y: 0 }}
  />
  <VictoryAxis
    style={{
      axis: { stroke: colors.secondary },
      tickLabels: { fontSize: unit, fill: colors.primary },
      labels: { fontSize: unit * 0.5, fill: colors.primary },
      ticks: { stroke: colors.secondary, size: 5 },
    }}
    tickFormat={(t) => `${new Date(t).toLocaleString()}`}
    tickValues={students_per_session.map(s => s['x'])}
    minDomain={{ x: 0, y: 0 }}
  />
</VictoryChart>
*/

}