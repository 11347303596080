import React from 'react';
import { LanguageContext } from '../containers/Language';
import { useContext } from 'react';
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag';
import { GET_DOJO_DETAILS } from '../pages/dojo'
import { Text } from '../containers/Language';


export const CREATE_DISCIPLINE = gql`
mutation createDiscipline($input:DisciplineInput!,$dojoId:Int!){
  createDiscipline(input:$input,dojoId:$dojoId){
    discipline{
      name,
      id
    }
  }
}`;


export default function NewDisciplineForm({ dojoId }) {

  const [disciplineName, setDisciplineName] = React.useState("");


  const [addDisciplineState, setaddDisciplineState] = React.useState(false);

  function addDiscipline() {
    setaddDisciplineState(true);
  }


  const [create, { loading, error }] = useMutation(
    CREATE_DISCIPLINE,
    {
      variables: { dojoId },
      refetchQueries: [
        {
          query: GET_DOJO_DETAILS,
          variables: { dojoId },
        },
      ],
      onCompleted({ create }) {
        setaddDisciplineState(false)
      }
    }
  );


  function onChangeDisciplineName(event) {
    const disciplineName_ = event.target.value;
    setDisciplineName(disciplineName_);
  };


  function onSubmit(event) {
    event.preventDefault();
    var discipline = {
      name: disciplineName
    }
    create({ variables: { input: discipline, dojoId: dojoId } });
  };

  const languageContext = useContext(LanguageContext);

  if (loading) return <p>Loading</p>;
  if (error) return <p>An error occurred: {error.message}</p>;


  if (addDisciplineState) {
    return (
      <div className='dialog_card'>
        <div className='dialog_card_inner'>
          <h2><Text tid="newDiscipline" /></h2>
          <form onSubmit={onSubmit}>

            <input
              required
              className="form-control"
              type="text"
              name="Name"
              placeholder={languageContext.dictionary["name"] || "name"}
              data-testid="name-input"
              onChange={onChangeDisciplineName}
            />

            <button className="btn btn-primary m-2" type="submit"><Text tid="CreateDiscipline" /></button>

            <button className="btn btn-primary m-2" type="cancel" onClick={() =>  setaddDisciplineState(false)}><Text tid="Cancel" /></button>

          </form >
        </div>
      </div>
    )
  } else {
    return (
      <button className="btn btn-primary" onClick={addDiscipline} >
        <Text tid="AddDiscipline" />
      </ button>
    )
  }

}

